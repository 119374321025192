let config;
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
let calendarYear, calendarMonth;
let isMobile;
export const init = (c, isMobileLayout) => {
  console.log("FACTORY", "init", c);
  config = c;
  isMobile = isMobileLayout;
  let now = new Date();
  calendarMonth = now.getMonth();
  calendarYear = now.getFullYear();
};

export const BASE64 = {};

const isMobileLayout = () => {
  return isMobile;
};

export const getImgUrl = (image, width, height) => {
  return `${config.baseUrlImager}/${config.tId}/images/${image}?format=webp&width=${width}&height=${height}&quality=${config.imgQuality}`;
};

export const toHTML = (component) => {
  console.log("FACTORY", "toHTML", JSON.stringify(component));
  switch (component.type) {
    case "Space":
      return `<div class="space ${component.size}"></div>`;
    case "Title":
      return `<div class="content"><h${component.level}>${component.title}</h${component.level}></div>`;
    case "Paragraph":
      return `<div class="content ${component.background}">${component.description}</div>`;
    case "InfoCards":
      return `<div class="section">
                          <section>${component.elements.reduce(
                            (accumulator, info) => {
                              return accumulator + toHTMLInfo(info);
                            },
                            ""
                          )}
              </section>
                        </div>`;
    case "Faq":
      return `<div id="faq_groups_container">
                          <div id="faq_titles_container">${component.elements.reduce(
                            (accumulator, faqGroup, index) =>
                              accumulator +
                              `
                              <p class="faq-group-title ${
                                index === 0 ? "active" : ""
                              }" onclick="showFaqGroup(${index})">${
                                faqGroup.title
                              }</p>
                          `,
                            ""
                          )}
                          </div>
                          <div id="faqs_container">
                              ${component.elements.reduce(
                                (accumulator, faqGroup, index) =>
                                  accumulator +
                                  `
                                  <div id="faq_group_${index}" style="display: ${
                                    index === 0 ? "block" : "none"
                                  }">
                                      ${faqGroup.elements.reduce(
                                        (acc, faq, faqIndex) =>
                                          acc +
                                          `
                                          <p class="accordion" onclick="toggleAccordion(this)">${faq.q}</p>
                                          <div class="panel"><p>${faq.a}</p></div>
                                      `,
                                        ""
                                      )}
                                  </div>
                                  
                              `,
                                ""
                              )}
                          </div>
                      </div>`;
    case "Audibles":
    case "Tours":
    case "Blogs":
    case "Products":
      return `<div class="section">
                          <section>${component.elements.reduce(
                            (accumulator, sId) => {
                              return accumulator + toHTMLCard(sId);
                            },
                            ""
                          )}
              </section>
            </div>`;
  }
};

export const toHTMLCard = (sId) => {
  switch (sId.charAt(0)) {
    case "T":
      const tour = config.tours.find((tour) => tour.sId === sId);
      return `<div class="card" onclick="showDetails('${tour.sId}')">
      ${
        BASE64[tour.images[0]]
          ? `<img alt="${tour.name}" data-id="${
              tour.images[0]
            }" onError="this.src='/images/${
              tour.images[0]
            }'; this.onerror=null;" src="${BASE64[tour.images[0]]}">`
          : `<img alt="${tour.name}" onError="this.src='/images/${
              tour.images[0]
            }'; this.onerror=null;" src="${getImgUrl(
              tour.images[0],
              240 * window.devicePixelRatio,
              160 * window.devicePixelRatio
            )}">`
      }
      <div class="${tour.category}">${tour.category
        .toUpperCase()
        .replaceAll("-", " ")}</div>
                  <div class="name">${tour.name}</div>
                  <div class="price">€${tour.price}</div>
                  ${
                    tour.tag
                      ? `<div class="tag">
                        ${tour.tag.toUpperCase().replaceAll("-", " ")}
                      </div>`
                      : ``
                  }
                  
               </div>`;
    case "A":
      const audible = config.audibles.find((product) => product.sId === sId);
      return `<div class="audible" onclick="showDetails('${audible.sId}')">
      ${
        BASE64[audible.slides[0].img]
          ? `<img alt="${audible.name}" data-id="${
              audible.slides[0].img
            }" onError="this.src='/images/${
              audible.slides[0].img
            }'; this.onerror=null;" src="${BASE64[audible.slides[0].img]}">`
          : `<img alt="${audible.name}" onError="this.src='/images/${
              audible.slides[0].img
            }'; this.onerror=null;" src="${getImgUrl(
              audible.slides[0].img,
              240 * window.devicePixelRatio,
              160 * window.devicePixelRatio
            )}">`
      }
      <div class="audibles">AUDIBLES</div>
                  <div class="name">${audible.name}</div>
                  <div class="price">Listen</div>
                  ${
                    audible.tag
                      ? `<div class="tag">
                        ${audible.tag.toUpperCase().replaceAll("-", " ")}
                      </div>`
                      : ``
                  }
               </div>`;
  }
};

export const toHTMLInfo = (info) => {
  return `<div class="info ${info.background} ${info.size}${
    info.size === "xl" ? ` ${info.flexDirection}` : ``
  } ${info.label || info.title || info.content ? `labeled` : `unlabeled`}${
    info.link &&
    !(info.size === "xl" && (info.label || info.title || info.content))
      ? ` link" onclick="showDetails('${info.link}')"`
      : `"`
  }>
    ${
      BASE64[info.img]
        ? `<img alt="${info.title}" data-id="${
            info.img
          }" onError="this.src='/images/${
            info.img
          }'; this.onerror=null;" src="${BASE64[info.img]}">`
        : `<img alt="${info.title}" onError="this.src='/images/${
            info.img
          }'; this.onerror=null;" src="${getImgUrl(
            info.img,
            getInfoWidth(info.size),
            getInfoHeight(info.size)
          )}">`
    }
    ${
      info.label || info.title || info.content
        ? `<div>
            ${
              info.label
                ? info.size === "xl"
                  ? `<h1>${info.label}</h1>`
                  : `<h2>${info.label}</h2>`
                : ``
            }
            ${
              info.title
                ? info.size === "xl"
                  ? `<h2>${info.title}</h2>`
                  : `<h2 class="subtitle">${info.title}</h2>`
                : ``
            }
            ${info.content ? `<p>${info.content}</p>` : ``}
        ${
          info.size === "xl" && info.link
            ? `<div class="button" onclick="showDetails('${info.link}')">Read More</div>`
            : ``
        }</div>`
        : ``
    }   
        </div>`;
};

const toImgSlide = (imgSrc, altText, width, height, lazyLoad, id) => {
  return BASE64[imgSrc]
    ? `<img${
        id ? ` id="${id}"` : ``
      } class="slide" alt="${altText}" data-id="${imgSrc}" onError="this.src='/images/${imgSrc}'; this.onerror=null;" src="${
        BASE64[imgSrc]
      }"${lazyLoad ? ' loading="lazy"' : ""}>`
    : `<img${
        id ? ` id="${id}"` : ``
      } class="slide" alt="${altText}" onError="this.src='/images/${imgSrc}'; this.onerror=null;" src="${getImgUrl(
        imgSrc,
        width,
        height
      )}"${lazyLoad ? ' loading="lazy"' : ""}/>`;
};

export const toHTMLDetails = (sId) => {
  const tour = config.tours.find((tour) => tour.sId === sId);
  return (
    `<p class="${tour.category}">${tour.category.replaceAll("-", " ")}</p>
    <h1 id="header_title">${tour.name}</h1>
    <div id="details_slides_carousel">
    
                    <div id="details_slides">${tour.images.reduce(
                      (acc, imgSrc, index) =>
                        acc +
                        toImgSlide(
                          imgSrc,
                          tour.name + " " + index,
                          getSlideWidth(index),
                          getSlideHeight(index),
                          index !== 0
                        ),
                      ""
                    )}
                      </div>
                      <div id="dots">
                      ${tour.images.reduce(
                        (acc, img, index) =>
                          acc +
                          (index === 0
                            ? `<div class="dot active"></div>`
                            : `<div class="dot"></div>`),
                        ""
                      )}
                      </div>
                      
      </div>
                 <div id="details_slides_container">
                  <img alt="${tour.name}" id="slide">
                    <div id="slide_arrow_container">
                        <svg id="prev_slide_arrow" width="28" height="28" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g>
                            <path d="M13 57H114V70H13V57Z" fill="white"></path>
                            <path d="M13 56.9312L70.3407 16.7809L77.7971 27.4298L20.4565 67.5802L13 56.9312Z" fill="white"></path>
                            <path d="M20.4565 59L77.7971 99.1503L70.3406 109.799L13 69.649L20.4565 59Z" fill="white"></path>
                          </g>
                        </svg>
                        <svg id="next_slide_arrow" width="28" height="28" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g transform="rotate(180,64,64)">
                            <path d="M13 57H114V70H13V57Z" fill="white"></path>
                            <path d="M13 56.9312L70.3407 16.7809L77.7971 27.4298L20.4565 67.5802L13 56.9312Z" fill="white"></path>
                            <path d="M20.4565 59L77.7971 99.1503L70.3406 109.799L13 69.649L20.4565 59Z" fill="white"></path>
                          </g>
                        </svg>
                    </div>
                    
                    <div id="close_slide_x">
                    ${svgClose}</div>
                </div>
                <div id="details_container">
          <div id="more_details">
            <h2 id="highlights_title">Highlights</h2>
            <h2 id="mobile_title">${tour.name}</h2>
            <p id="highlights">${tour.highlights}</p>
            <br>
            <h2>What's included</h2>
            <div id="inclusions">` +
    tour.inclusions.reduce(
      (acc, inclusion) =>
        acc +
        `<div class="inclusion">
                    ` +
        svgTick +
        `
                    <p>${inclusion}</p>
                  </div>`,
      ""
    ) +
    tour.exclusions.reduce(
      (acc, exclusion) =>
        acc +
        `<div class="exclusion">
    ` +
        svgX +
        `
                    <p>${exclusion}</p>
                  </div>`,
      ""
    ) +
    ` 
                
            </div>
            <br>
            <h2>What to expect</h2>
            <div class="clamped">
              <p id="expectations" >${tour.expectations}</p>
              <p id="clamp_toggle" class="clamped"></p>
            </div>
            <br>
            <h2>Activities</h2>
            <div id="activities">
            ` +
    tour.activities.reduce(
      (acc, activity, index) =>
        acc +
        `<div>
                  <div class="activity">
                  ${toImgSlide(
                    activity,
                    tour.captions[index],
                    getActivityWidth(),
                    getActivityHeight()
                  )}

        
                    <div>
                      <span>${index + 1}</span>
                      <p>${tour.captions[index]}</p>
                    </div>
                </div>
                ${index < tour.activities.length - 1 ? threeDotsSvg : ""}
              </div>`,
      ""
    ) +
    `
            </div>
  
          </div>
          <div id="floating_container">
          
            <div id="floating_container_header">
                <svg id="mobile_back" width="28" height="28" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" style="display: block;">
                  <g>
                    <path d="M13 57H114V70H13V57Z" fill="black"></path>
                    <path d="M13 56.9312L70.3407 16.7809L77.7971 27.4298L20.4565 67.5802L13 56.9312Z" fill="black"></path>
                    <path d="M20.4565 59L77.7971 99.1503L70.3406 109.799L13 69.649L20.4565 59Z" fill="black"></path>
                  </g>
                </svg>
                <h2 id="floating_container_title"></h2>
              </div>

              <div id="offline_container">
                <h3>Offline Mode</h3>
                <p>The calendar will be available when you have an active internet connection</p>
              </div>
            
              <div id="calendar_container">
                <div id="seats">
                  <div id="adult_card" class="active">
                    <div>Adult</div>
                    <div class="input">
                      <div id="adult_decrement" class="operator">-</div>
                      <div id="adult_input">1</div>
                      <div id="adult_increment" class="operator">+</div>
                    </div>
                  </div>
    
                  <div id="child_card">
                    <div>Child</div>
                    <div class="input">
                      <div id="child_decrement" class="operator">-</div>
                      <div id="child_input">0</div>
                      <div id="child_increment" class="operator">+</div>
                    </div>
                  </div>
    
                  <div id="infant_card">
                    <div>Infant</div>
                    <div class="input">
                      <div id="infant_decrement" class="operator">-</div>
                      <div id="infant_input">0</div>
                      <div id="infant_increment" class="operator">+</div>
                    </div>
                  </div>
                </div>
                <div id="calendar">
                  <div id="calendar_month">
                    <p id="prev_month">&#10094;</p>
                    <h3 id="calendar_header">${
                      MONTHS[calendarMonth] + " " + calendarYear
                    }</h3>
                    <p id="next_month">&#10095;</p>
                  </div>
                  <ul class="weekdays">
                    <li>Mo</li>
                    <li>Tu</li>
                    <li>We</li>
                    <li>Th</li>
                    <li>Fr</li>
                    <li>Sa</li>
                    <li>Su</li>
                  </ul>
                  <ul id="days"></ul>
                  <p id="availability_label">Loading Slots</p>
                  <div id="calendar_slot"></div>
    
                </div>
    
            </div>
           
          </div>
        </div>
  
        <div id="reserve_bar">
          <div id="reserve_button">Reserve</div>
        </div>
  
        ${
          tour.address
            ? `<div id="meeting_instructions">
        <div>
          <svg width="64" height="64" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="white"
                fill-rule="evenodd"
                d="M11.291 21.706 12 21l-.709.706zM12 21l.708.706a1 1 0 0 1-1.417 0l-.006-.007-.017-.017-.062-.063a47.708 47.708 0 0 1-1.04-1.106 49.562 49.562 0 0 1-2.456-2.908c-.892-1.15-1.804-2.45-2.497-3.734C4.535 12.612 4 11.248 4 10c0-4.539 3.592-8 8-8 4.408 0 8 3.461 8 8 0 1.248-.535 2.612-1.213 3.87-.693 1.286-1.604 2.585-2.497 3.735a49.583 49.583 0 0 1-3.496 4.014l-.062.063-.017.017-.006.006L12 21zm0-8a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                clip-rule="evenodd"
              />
            </svg>
        </div>
        <p id="map_content">${tour.address.content}</p>
      </div>`
            : ``
        }
        
        
        
        ${
          tour.links && tour.links.length > 0
            ? `
        <p id="links_header" class="section-header">Other people booked...</p>
            <section id="links">
                ${tour.links.reduce((acc, linkedId, index) => {
                  const linkedProduct = config.tours.find(
                    (product) => product.sId === linkedId
                  );
                  return (
                    acc +
                    `<a class="card not-ready" href="${toUrlPath(
                      linkedProduct.name
                    )}">
                        <img class="card-img" data-id="${
                          linkedProduct.images[0]
                        }">
                        <p class="title">${linkedProduct.name}</p>
                        <p class="desc">${linkedProduct.highlights}</p>
                      </a>`
                  );
                }, "")}
            </section>
            
        `
            : ``
        }
    `
  );
};

export const toHTMLAudible = (sId) => {
  const currentProduct = config.audibles.find((tour) => tour.sId === sId);
  return `
  <div id="audible_container">
    <div id="video_container">
    ${toImgSlide(
      currentProduct.slides[0].img,
      "Audible Poster",
      window.devicePixelRatio *
        (isMobileLayout() ? 0.9 : 0.32) *
        window.innerWidth,
      window.devicePixelRatio *
        (isMobileLayout() ? 0.9 : 0.32) *
        window.innerWidth,
      false,
      "poster"
    )}
      <p id="caption"></p>
      <audio id="${currentProduct.sId}"><source type="audio/mpeg" src="audios/${
    currentProduct.audio
  }"></audio>
  
        <div id="legend">
          ${toImgSlide(
            currentProduct.legend,
            "Map Legend",
            window.devicePixelRatio *
              (isMobileLayout() ? 0.9 : 0.32) *
              window.innerWidth,
            window.devicePixelRatio *
              (isMobileLayout() ? 0.9 : 0.32) *
              window.innerWidth,
            true
          )}
         
        <div id="markers_container"></div>
      </div>
      <div id="chapters_toggle">${svgPin}</div>
    </div> 
    
    <div id="controls_container">
      <div id="controls">
        <div id="media_buttons">
          <svg id="prev_chapter" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.2409 7.21957V16.7896C20.2409 18.7496 18.1109 19.9796 16.4109 18.9996L12.2609 16.6096L8.11094 14.2096C6.41094 13.2296 6.41094 10.7796 8.11094 9.79957L12.2609 7.39957L16.4109 5.00957C18.1109 4.02957 20.2409 5.24957 20.2409 7.21957Z"/>
            <path d="M3.76172 18.9303C3.35172 18.9303 3.01172 18.5903 3.01172 18.1803V5.82031C3.01172 5.41031 3.35172 5.07031 3.76172 5.07031C4.17172 5.07031 4.51172 5.41031 4.51172 5.82031V18.1803C4.51172 18.5903 4.17172 18.9303 3.76172 18.9303Z"/>
          </svg>
          <div id="play">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.88 122.88">
              <path d="M61.44,0c33.93,0,61.44,27.51,61.44,61.44s-27.51,61.44-61.44,61.44S0,95.37,0,61.44S27.51,0,61.44,0L61.44,0z M84.91,65.52c3.41-2.2,3.41-4.66,0-6.61L49.63,38.63c-2.78-1.75-5.69-0.72-5.61,2.92l0.11,40.98c0.24,3.94,2.49,5.02,5.8,3.19 L84.91,65.52L84.91,65.52z"/>
            </svg>
          </div>
          <svg id="next_chapter" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.76172 7.21957V16.7896C3.76172 18.7496 5.89172 19.9796 7.59172 18.9996L11.7417 16.6096L15.8917 14.2096C17.5917 13.2296 17.5917 10.7796 15.8917 9.79957L11.7417 7.39957L7.59172 5.00957C5.89172 4.02957 3.76172 5.24957 3.76172 7.21957Z"/>
            <path d="M20.2383 18.9303C19.8283 18.9303 19.4883 18.5903 19.4883 18.1803V5.82031C19.4883 5.41031 19.8283 5.07031 20.2383 5.07031C20.6483 5.07031 20.9883 5.41031 20.9883 5.82031V18.1803C20.9883 18.5903 20.6583 18.9303 20.2383 18.9303Z"/>
          </svg> 
        </div>
      </div>
     
      <h2 id="current_chapter">${currentProduct.chapters[0].name}</h2>
      <div id="chapter_dots">${currentProduct.chapters.reduce(
        (acc, chap, index) =>
          acc +
          (index === 0
            ? `<div class="dot active"></div>`
            : `<div class="dot"></div>`),
        ""
      )}</div>
      <h1 id="audible_title" aria-disabled="true">${currentProduct.name}</h1>
      
  </div>

  <div id="chapters_container"></div>
</div>`;
};

export const toUrlPath = (title) => {
  return title
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "")
    .split("-")
    .filter(
      (token) =>
        token &&
        token !== "a" &&
        token !== "an" &&
        token !== "in" &&
        token !== "on" &&
        token !== "to" &&
        token !== "does" &&
        token !== "has" &&
        token !== "into" &&
        token !== "with" &&
        token !== "of" &&
        token !== "the"
    )
    .join("-");
};

const threeDotsSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="5" height="35" viewBox="0 0 5 35" style="margin: 10px">
                <g transform="translate(-549 -2424)">
                    <circle id="Ellipse_7" data-name="Ellipse 7" cx="2.5" cy="2.5" r="2.5" transform="translate(549 2424)" fill="#e5e5e5"></circle>
                    <circle id="Ellipse_7-2" data-name="Ellipse 7" cx="2.5" cy="2.5" r="2.5" transform="translate(549 2439)" fill="#e5e5e5"></circle>
                    <circle id="Ellipse_7-3" data-name="Ellipse 7" cx="2.5" cy="2.5" r="2.5" transform="translate(549 2454)" fill="#e5e5e5"></circle>
                </g>
            </svg>`;

const svgTick = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M38.5 6.5L34.5 3L12 28L4 19.5L1 24.5L12 35.5L38.5 6.5Z" fill="black" stroke="black"/>
</svg>`;

const svgX = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.08441 33.0184L33.0184 2.08445L37 6.06605L6.06601 37L2.08441 33.0184Z" fill="black"/>
<path d="M32.934 36.9156L1.99997 5.9816L5.98158 2L36.9156 32.934L32.934 36.9156Z" fill="black"/>
</svg>`;

export const svgPin = `<svg width="28" height="28" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    d="M11.291 21.706 12 21l-.709.706zM12 21l.708.706a1 1 0 0 1-1.417 0l-.006-.007-.017-.017-.062-.063a47.708 47.708 0 0 1-1.04-1.106 49.562 49.562 0 0 1-2.456-2.908c-.892-1.15-1.804-2.45-2.497-3.734C4.535 12.612 4 11.248 4 10c0-4.539 3.592-8 8-8 4.408 0 8 3.461 8 8 0 1.248-.535 2.612-1.213 3.87-.693 1.286-1.604 2.585-2.497 3.735a49.583 49.583 0 0 1-3.496 4.014l-.062.063-.017.017-.006.006L12 21zm0-8a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
    clip-rule="evenodd"
  />
</svg>`;

export const svgClose = `<svg width="28" height="28" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
<path d="M23.1949 94.6127L94.6127 23.1949L103.805 32.3873L32.3873 103.805L23.1949 94.6127Z"/>
<path d="M94.4178 103.61L23 32.1924L32.1924 23L103.61 94.4178L94.4178 103.61Z"/>
</svg>`;

const getPosterWidth = () => {
  const totalWidth = window.innerWidth * window.devicePixelRatio;
  return Math.round(isMobileLayout() ? totalWidth : 0.666666 * totalWidth);
};

const getPosterHeight = () => {
  const totalWidth = window.innerWidth * window.devicePixelRatio;
  return Math.round(
    isMobileLayout() ? 0.5 * totalWidth : 0.333333 * totalWidth
  );
};

const getInfoWidth = (size) => {
  const totalWidth = window.innerWidth * window.devicePixelRatio;
  switch (size) {
    case "sm":
      return Math.round(
        isMobileLayout() ? 0.81 * totalWidth : 0.125 * totalWidth
      );
    case "lg":
      return Math.round(
        isMobileLayout() ? 0.81 * totalWidth : 0.33 * totalWidth
      );
    case "xl":
      return Math.round(
        isMobileLayout() ? 0.81 * totalWidth : 0.6666 * totalWidth
      );
    default:
      return Math.round(
        isMobileLayout() ? 0.81 * totalWidth : 0.21 * totalWidth
      );
  }
};

const getInfoHeight = (size) => {
  const totalWidth = window.innerWidth * window.devicePixelRatio;
  switch (size) {
    case "sm":
      return Math.round(
        isMobileLayout() ? 0.81 * totalWidth : 0.125 * totalWidth
      );
    case "lg":
      return Math.round(
        isMobileLayout() ? 0.81 * totalWidth : 0.33 * totalWidth
      );
    case "xl":
      return Math.round(
        isMobileLayout() ? 0.81 * totalWidth : 0.3333 * totalWidth
      );
    default:
      return Math.round(
        isMobileLayout() ? 0.81 * totalWidth : 0.21 * totalWidth
      );
  }
};

const getSlideWidth = (index) => {
  const totalWidth = window.innerWidth * window.devicePixelRatio;
  return Math.round(
    isMobileLayout()
      ? totalWidth
      : index === 0
      ? 0.3 * totalWidth
      : 0.145 * totalWidth
  );
};

const getSlideHeight = (index) => {
  const totalWidth = window.innerWidth * window.devicePixelRatio;
  return Math.round(
    isMobileLayout()
      ? 0.6666 * totalWidth
      : index === 0
      ? 0.2 * totalWidth
      : 0.095 * totalWidth
  );
};

const getActivityWidth = () => {
  const totalWidth = window.innerWidth * window.devicePixelRatio;
  return Math.round(isMobileLayout() ? 0.5 * totalWidth : 0.25 * totalWidth);
};

const getActivityHeight = () => {
  const totalWidth = window.innerWidth * window.devicePixelRatio;
  return Math.round(
    isMobileLayout() ? 0.3333 * totalWidth : 0.1666 * totalWidth
  );
};
