import { useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import { isEqual, cloneDeep } from "lodash";
import LogoInverted from "./assets/logo-inverted.svg";
import "@aws-amplify/ui-react/styles.css";

import {
  Routes,
  Route,
  NavLink,
  Outlet,
  BrowserRouter,
} from "react-router-dom";

import {
  AlignLeftOutlined,
  BgColorsOutlined,
  CloseCircleFilled,
  CustomerServiceOutlined,
  EuroOutlined,
  FireOutlined,
  GlobalOutlined,
  ScheduleOutlined,
  SettingOutlined,
  UnlockOutlined,
  WindowsOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Col,
  Flex,
  Layout,
  Popconfirm,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import Pages from "./Pages";
import Schedule from "./Schedule";
import Settings from "./Settings";
import Tours from "./Tours";
import Audibles from "./Audibles";
import {
  CardSize,
  BorderRadius,
  COLORS,
  ComponentType,
  Config,
  FONTS,
  FontSize,
} from "./domain/domain";
import { GetStarted } from "./GetStarted";
import {
  COMPLETE_LOGO,
  DEFAULT_IMG,
  GHOST_LOGO,
  MAP_PIN,
  NO_INDEX,
  SQUARE_LOGO,
} from "./Utils";
import { PreviewWebsite } from "./PreviewWebsite";
import { WaitingDeployment } from "./WaitingDeployment";
import { Transactions } from "./Transactions";
import { environment } from "./environments/environment";
import {
  toHTML,
  toHTMLAudible,
  toHTMLDetails,
  init,
  BASE64,
  toUrlPath,
} from "./modules/factory.mjs";

const SOCIALS = {
  facebook: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
  <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h10v-9h-3v-3h3v-1.611C16,9.339,17.486,8,20.021,8 c1.214,0,1.856,0.09,2.16,0.131V11h-1.729C19.376,11,19,11.568,19,12.718V14h3.154l-0.428,3H19v9h5c1.105,0,2-0.895,2-2V6 C26,4.895,25.104,4,24,4z"></path>
</svg>`,
  tiktok: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
  <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.104,4,24,4z M22.689,13.474 c-0.13,0.012-0.261,0.02-0.393,0.02c-1.495,0-2.809-0.768-3.574-1.931c0,3.049,0,6.519,0,6.577c0,2.685-2.177,4.861-4.861,4.861 C11.177,23,9,20.823,9,18.139c0-2.685,2.177-4.861,4.861-4.861c0.102,0,0.201,0.009,0.3,0.015v2.396c-0.1-0.012-0.197-0.03-0.3-0.03 c-1.37,0-2.481,1.111-2.481,2.481s1.11,2.481,2.481,2.481c1.371,0,2.581-1.08,2.581-2.45c0-0.055,0.024-11.17,0.024-11.17h2.289 c0.215,2.047,1.868,3.663,3.934,3.811V13.474z"></path>
</svg>`,
  youtube: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
<path d="M 15 4 C 10.814 4 5.3808594 5.0488281 5.3808594 5.0488281 L 5.3671875 5.0644531 C 3.4606632 5.3693645 2 7.0076245 2 9 L 2 15 L 2 15.001953 L 2 21 L 2 21.001953 A 4 4 0 0 0 5.3769531 24.945312 L 5.3808594 24.951172 C 5.3808594 24.951172 10.814 26.001953 15 26.001953 C 19.186 26.001953 24.619141 24.951172 24.619141 24.951172 L 24.621094 24.949219 A 4 4 0 0 0 28 21.001953 L 28 21 L 28 15.001953 L 28 15 L 28 9 A 4 4 0 0 0 24.623047 5.0546875 L 24.619141 5.0488281 C 24.619141 5.0488281 19.186 4 15 4 z M 12 10.398438 L 20 15 L 12 19.601562 L 12 10.398438 z"></path>
</svg>`,
  instagram: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
  <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"></path>
</svg>`,
  x: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
  <path d="M 6 4 C 4.895 4 4 4.895 4 6 L 4 24 C 4 25.105 4.895 26 6 26 L 24 26 C 25.105 26 26 25.105 26 24 L 26 6 C 26 4.895 25.105 4 24 4 L 6 4 z M 8.6484375 9 L 13.259766 9 L 15.951172 12.847656 L 19.28125 9 L 20.732422 9 L 16.603516 13.78125 L 21.654297 21 L 17.042969 21 L 14.056641 16.730469 L 10.369141 21 L 8.8945312 21 L 13.400391 15.794922 L 8.6484375 9 z M 10.878906 10.183594 L 17.632812 19.810547 L 19.421875 19.810547 L 12.666016 10.183594 L 10.878906 10.183594 z"></path>
  </svg>`,
  linkedIn: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
  <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
</svg>`,
  phone: `<svg
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M3 5.5C3 14.0604 9.93959 21 18.5 21C18.8862 21 19.2691 20.9859 19.6483 20.9581C20.0834 20.9262 20.3009 20.9103 20.499 20.7963C20.663 20.7019 20.8185 20.5345 20.9007 20.364C21 20.1582 21 19.9181 21 19.438V16.6207C21 16.2169 21 16.015 20.9335 15.842C20.8749 15.6891 20.7795 15.553 20.6559 15.4456C20.516 15.324 20.3262 15.255 19.9468 15.117L16.74 13.9509C16.2985 13.7904 16.0777 13.7101 15.8683 13.7237C15.6836 13.7357 15.5059 13.7988 15.3549 13.9058C15.1837 14.0271 15.0629 14.2285 14.8212 14.6314L14 16C11.3501 14.7999 9.2019 12.6489 8 10L9.36863 9.17882C9.77145 8.93713 9.97286 8.81628 10.0942 8.64506C10.2012 8.49408 10.2643 8.31637 10.2763 8.1317C10.2899 7.92227 10.2096 7.70153 10.0491 7.26005L8.88299 4.05321C8.745 3.67376 8.67601 3.48403 8.55442 3.3441C8.44701 3.22049 8.31089 3.12515 8.15802 3.06645C7.98496 3 7.78308 3 7.37932 3H4.56201C4.08188 3 3.84181 3 3.63598 3.09925C3.4655 3.18146 3.29814 3.33701 3.2037 3.50103C3.08968 3.69907 3.07375 3.91662 3.04189 4.35173C3.01413 4.73086 3 5.11378 3 5.5Z"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>`,
  email: `<svg
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect width="24" height="24" opacity="0" />
  <path d="M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zm-.67 2L12 10.75 5.67 6zM19 18H5a1 1 0 0 1-1-1V7.25l7.4 5.55a1 1 0 0 0 .6.2 1 1 0 0 0 .6-.2L20 7.25V17a1 1 0 0 1-1 1z" />
</svg>`,
  address: `<svg
  
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    d="M11.291 21.706 12 21l-.709.706zM12 21l.708.706a1 1 0 0 1-1.417 0l-.006-.007-.017-.017-.062-.063a47.708 47.708 0 0 1-1.04-1.106 49.562 49.562 0 0 1-2.456-2.908c-.892-1.15-1.804-2.45-2.497-3.734C4.535 12.612 4 11.248 4 10c0-4.539 3.592-8 8-8 4.408 0 8 3.461 8 8 0 1.248-.535 2.612-1.213 3.87-.693 1.286-1.604 2.585-2.497 3.735a49.583 49.583 0 0 1-3.496 4.014l-.062.063-.017.017-.006.006L12 21zm0-8a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
    clip-rule="evenodd"
  />
</svg>`,
};

function App({ signOut, user }) {
  if (window.location.search.includes("session_id")) {
    window.location.replace(
      window.location.protocol + "//" + window.location.host
    );
  }
  const [isFirstRun, setFirstRun] = useState(false);
  const [config, setConfig] = useState<Config>();
  const [publishedConfig, setPublishedConfig] = useState<Config>();

  const openBilling = () => {
    fetch(`${environment.baseUrl}/api/billing`, {
      headers: {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => window.open(data.url, "_self"));
  };

  const fetchConfig = () => {
    fetch(`${environment.baseUrl}/api/config`, {
      headers: {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((config) => {
            setTimeout(() => {
              setConfig(config);
              setPublishedConfig(cloneDeep(config));
            }, 500);
          });
        } else if (res.status === 418) {
          // Config not initialized
          setFirstRun(true);
        }
      })
      .catch(() => {
        setTimeout(fetchConfig, 10000);
      });
  };
  useEffect(fetchConfig, [user]);

  useEffect(() => {
    console.log("Zconfig", config);
    console.log("ZpublishedConfig", publishedConfig);
    init(config, true);
  }, [config]);

  const fillStylesAndConfig = async (
    indexHtml: string,
    styleCss: string,
    mobileCss: string
  ) => {
    let pageLinks = config.pages.reduce(
      (acc, page) =>
        acc + `<p onclick="showDetails('${page.sId}')">${page.name}</p>`,
      ""
    );

    let blogLinks = config.blogs.reduce(
      (acc, page) =>
        acc + `<p onclick="showDetails('${page.sId}')">${page.name}</p>`,
      ""
    );

    let socialLinks = "";
    if (config.facebook) {
      socialLinks += `<a title="Facebook" aria-label="Facebook" target="_blank" href="${config.facebook}">${SOCIALS.facebook}</a>`;
    }
    if (config.tiktok) {
      socialLinks += `<a title="Tiktok" aria-label="Tiktok" target="_blank" href="${config.tiktok}">${SOCIALS.tiktok}</a>`;
    }
    if (config.youtube) {
      socialLinks += `<a title="Youtube" aria-label="Youtube" target="_blank" href="${config.youtube}">${SOCIALS.youtube}</a>`;
    }
    if (config.instagram) {
      socialLinks += `<a title="Instagram" aria-label="Instagram" target="_blank" href="${config.instagram}">${SOCIALS.instagram}</a>`;
    }
    if (config.x) {
      socialLinks += `<a title="X" aria-label="X" target="_blank" href="${config.x}">${SOCIALS.x}</a>`;
    }
    if (config.linkedIn) {
      socialLinks += `<a title="LinkedIn" aria-label="LinkedIn" target="_blank" href="${config.linkedIn}">${SOCIALS.linkedIn}</a>`;
    }

    let contactLinks = "";
    if (config.phone) {
      contactLinks += `<a target="_blank" href="tel:${config.phone}">${SOCIALS.phone}${config.phone}</a>`;
    }
    if (config.email) {
      contactLinks += `<a target="_blank" href="mailto:${config.email}">${SOCIALS.email}${config.email}</a>`;
    }
    if (config.address) {
      contactLinks += `<p id="address_link">${SOCIALS.address}${config.address.content}</p>`;
    }

    const primaryFontBase64 = await toBase64(
      `../client/fonts/${config.primaryFont.toLowerCase()}.woff2`
    );

    const secondaryFontBase64 = await toBase64(
      `../client/fonts/${config.secondaryFont.toLowerCase()}.woff2`
    );

    const logoUrl = getImgUrl(config.fullLogo, 32, 8);
    const logoBase64 = await toBase64(logoUrl);

    const squareLogoSvg = await fetch(getImgUrl(config.squareLogo, 0, 0)).then(
      (res) => res.text()
    );

    const ghostLogoSvg = await fetch(getImgUrl(config.ghostLogo, 0, 0)).then(
      (res) => res.text()
    );

    const fullLogoSvg = await fetch(getImgUrl(config.fullLogo, 0, 0)).then(
      (res) => res.text()
    );

    return indexHtml
      .replace("1988-03-08T10-20-30", version)
      .replace("<!-- squareLogoSvg -->", squareLogoSvg)
      .replace("<!-- ghostLogoSvg -->", ghostLogoSvg)
      .replace("<!-- fullLogoSvg -->", fullLogoSvg)
      .replaceAll("placeholder-square-logo", config.squareLogo)
      .replace("placeholder-tenant-id", config.tId)
      .replace(
        "<!-- googleTagManager -->",
        config.googleTagManager
          ? `<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${config.googleTagManager}');</script>`
          : ""
      )
      .replace(
        "<!-- googleTagManagerNoScript -->",
        config.googleTagManager
          ? `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${config.googleTagManager}"
          height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`
          : ""
      )
      .replace(
        "<!-- googleSiteVerification -->",
        config.googleSiteVerification
          ? `<meta name="google-site-verification" content="${config.googleSiteVerification}" />`
          : ""
      )
      .replace(
        "<!-- themeColor -->",
        `<meta name="theme-color" content="${config.primaryColor}"/>`
      )
      .replace(
        "<!-- apple-mobile-web-app-status-bar -->",
        `<meta name="apple-mobile-web-app-status-bar" content="${config.primaryColor}"/>`
      )
      .replace(
        "/*style.css*/",
        styleCss
          .replace("#123456", config.primaryColor)
          .replace("#234561", config.textColor)
          .replace("#345612", config.primaryDarkColor)
          .replace("101px", config.fontSize)
          .replace("102px", config.borderRadius)
          .replace("103px", config.cardSize)
          .replaceAll("Arial", config.primaryFont)
          .replaceAll("Times", config.secondaryFont)
          .replace(
            "/* FontFaces */",
            config.primaryFont === config.secondaryFont
              ? `@font-face { 
            font-family: "${config.primaryFont}";
            src: url(${primaryFontBase64});
          }`
              : `@font-face { 
            font-family: "${config.primaryFont}";
            src: url(${primaryFontBase64});
          } @font-face { 
            font-family: "${config.secondaryFont}";
            src: url(${secondaryFontBase64});
          }`
          )
      )
      .replace("/*mobile.css*/", mobileCss)
      .replaceAll("<!-- companyName -->", config.companyName)
      .replaceAll(
        "<!-- logoImg -->",
        `<img id="logo-img" alt="${config.companyName}" data-id="${config.fullLogo}" src="${logoBase64}" width="128px" height="32px">`
      )
      .replaceAll("<!-- pageLinks -->", pageLinks)
      .replaceAll("<!-- blogLinks -->", blogLinks)
      .replaceAll("<!-- socialLinks -->", socialLinks)
      .replaceAll("<!-- contactLinks -->", contactLinks);
  };

  const updateVersion = () => {
    console.log("updateVersion", version);
    return Auth.updateUserAttributes(user, {
      "custom:version": version,
    });
  };

  const publishConfig = () => {
    console.log("publishConfig", version && config, version, config);
    if (version && config) {
      //fixme publish only the necessary files
      //fixme remove mainJs from here it's uploaded once at initialization
      setSpinning(true);
      uploadAllPages()
        .then(uploadManifest)
        .then(uploadConfig)
        .then(uploadMainJs)
        .then(uploadServiceWorker)
        .then(uploadFactory)
        .then(uploadSitemap)
        .then(updateVersion)
        .finally(() => {
          setSpinning(false);
        });
    }
  };

  const [version, setVersion] = useState<string>();
  useEffect(publishConfig, [version]);

  const discardChanges = () => {
    console.log("discardChanges", config);
    setConfig(cloneDeep(publishedConfig));
  };

  const isConfigEquals = () => {
    return (
      config.fullLogo === publishedConfig.fullLogo &&
      config.squareLogo === publishedConfig.squareLogo &&
      config.ghostLogo === publishedConfig.ghostLogo &&
      config.mapMarker === publishedConfig.mapMarker &&
      config.companyName === publishedConfig.companyName &&
      config.primaryColor === publishedConfig.primaryColor &&
      config.primaryFont === publishedConfig.primaryFont
    );
  };

  const hasObjectChanged = (object, type) => {
    return true;
    if (isConfigEquals()) {
      const publishedObj = publishedConfig[type].find(
        (obj) => obj.name === object.name
      );
      return !isEqual(object, publishedObj);
    } else {
      return true;
    }
  };

  const uploadAllPages = () => {
    console.log("uploadAllPages", config);
    return Promise.all([
      fetch("../client/index.html").then((resp) => resp.text()),
      fetch("../client/css/style.css").then((resp) => resp.text()),
      fetch("../client/css/mobile.css").then((resp) => resp.text()),
    ]).then(async (responses) => {
      let indexHtml = await fillStylesAndConfig(
        responses[0],
        responses[1],
        responses[2]
      );
      return Promise.all(
        config.pages
          .concat(config.blogs)
          .filter((page) => hasObjectChanged(page, "pages"))
          .map(
            (page) =>
              new Promise(async (resolve) => {
                let mainContent = "";
                for (let i = 0; i < page.components.length; i++) {
                  const component = page.components[i];
                  switch (component.type) {
                    case "InfoCards":
                      await Promise.all(
                        component.elements
                          .filter((element) => !BASE64[element.img])
                          .map((element) =>
                            toBase64(getImgUrl(element.img, 12, 8)).then(
                              (base64) => (BASE64[element.img] = base64)
                            )
                          )
                      );
                      break;

                    case "Products":
                      await Promise.all(
                        config.tours
                          .map((tour) => tour.images[0])
                          .concat(
                            config.audibles.map(
                              (audible) => audible.slides[0].img
                            )
                          )
                          .filter((img) => !BASE64[img])
                          .map((img) =>
                            toBase64(getImgUrl(img, 12, 8)).then(
                              (base64) => (BASE64[img] = base64)
                            )
                          )
                      );
                      break;
                  }

                  mainContent += await toHTML(component);
                }

                const blob = new Blob(
                  [indexHtml.replace("<!-- mainContent -->", mainContent)],
                  {
                    type: "text/html",
                  }
                );

                upload(blob, "", toUrlPath(page.name)).then(resolve);
              })
          )
          .concat(
            config.tours
              .filter((tour) => hasObjectChanged(tour, "tours"))
              .map(
                (tour) =>
                  new Promise(async (resolve) => {
                    await Promise.all(
                      tour.images
                        .concat(tour.activities)
                        .filter((img) => !BASE64[img])
                        .map((img) =>
                          toBase64(getImgUrl(img, 12, 8)).then(
                            (base64) => (BASE64[img] = base64)
                          )
                        )
                    );

                    let mainContent = toHTMLDetails(tour.sId);

                    const blob = new Blob(
                      [indexHtml.replace("<!-- mainContent -->", mainContent)],
                      {
                        type: "text/html",
                      }
                    );
                    upload(blob, "", toUrlPath(tour.name)).then(resolve);
                  })
              )
          )
          .concat(
            config.audibles
              .filter((audible) => hasObjectChanged(audible, "audibles"))
              .map(
                (currentProduct) =>
                  new Promise(async (resolve) => {
                    await toBase64(
                      getImgUrl(currentProduct.slides[0].img, 12, 12)
                    ).then(
                      (base64) =>
                        (BASE64[currentProduct.slides[0].img] = base64)
                    );
                    let mainContent = toHTMLAudible(currentProduct.sId);
                    const blob = new Blob(
                      [indexHtml.replace("<!-- mainContent -->", mainContent)],
                      {
                        type: "text/html",
                      }
                    );
                    upload(blob, "", toUrlPath(currentProduct.name)).then(
                      resolve
                    );
                  })
              )
          )
      );
    });
  };

  const uploadDefaultImg = () => {
    console.log("uploadDefaultImg");
    return fetch("../client/images/default.svg")
      .then((res) => res.blob())
      .then((blob) => {
        return upload(blob, "images/", DEFAULT_IMG);
      });
  };

  const uploadLogoFull = () => {
    console.log("uploadLogoFull");
    return fetch("../client/images/logo-complete.svg")
      .then((res) => res.blob())
      .then((blob) => {
        return upload(blob, "images/", COMPLETE_LOGO);
      });
  };

  const uploadLogoCompact = () => {
    console.log("uploadLogoCompact");
    return fetch("../client/images/logo-compact.svg")
      .then((res) => res.blob())
      .then((blob) => {
        return upload(blob, "images/", SQUARE_LOGO);
      });
  };

  const uploadLogoGhost = () => {
    console.log("uploadLogoGhost");
    return fetch("../client/images/logo-ghost.svg")
      .then((res) => res.blob())
      .then((blob) => {
        return upload(blob, "images/", GHOST_LOGO);
      });
  };

  const uploadMapPin = () => {
    console.log("uploadMapPin");
    return fetch("../client/images/map-pin.svg")
      .then((res) => res.blob())
      .then((blob) => {
        return upload(blob, "images/", MAP_PIN);
      });
  };

  const uploadConfig = () => {
    console.log("uploadConfig", config);
    const configBlob = new Blob([JSON.stringify(config)], {
      type: "application/json",
    });

    setPublishedConfig(cloneDeep(config));
    return upload(configBlob, "config/", `${version}.json`);
  };

  const uploadFactory = () => {
    console.log("uploadFactory");
    return fetch("../client/modules/factory.mjs")
      .then((res) => res.blob())
      .then((blob) => {
        return upload(blob, "modules/", `factory.mjs`);
      });
  };

  const uploadServiceWorker = () => {
    console.log("uploadServiceWorker");
    return fetch("../client/service-worker.js")
      .then((res) => res.text())
      .then((text) => {
        return upload(
          new Blob([text.replaceAll("1988-03-08T10-20-30", version)], {
            type: "text/javascript",
          }),
          "",
          `service-worker-${version}.js`
        );
      });
  };

  const uploadMainJs = () => {
    console.log("uploadMainJs");
    return fetch("../client/js/main.js")
      .then((res) => res.text())
      .then((text) => {
        if (user.attributes["custom:accountId"]) {
          return upload(
            new Blob(
              [
                text
                  .replace(
                    "const stripeOptions = undefined;",
                    `const stripeOptions = {stripeAccount: "${user.attributes["custom:accountId"]}"};`
                  )
                  .replaceAll("1988-03-08T10-20-30", version),
              ],
              {
                type: "text/javascript",
              }
            ),
            "js/",
            `${version}.js`
          );
        } else {
          return upload(
            new Blob([text.replaceAll("1988-03-08T10-20-30", version)], {
              type: "text/javascript",
            }),
            "js/",
            `${version}.js`
          );
        }
      });
  };

  const uploadFavIco = () => {
    return fetch("../client/images/favicon.svg")
      .then((resp) => resp.blob())
      .then((blob) => upload(blob, "images/", SQUARE_LOGO));
  };

  const uploadRobots = () => {
    console.log("uploadRobots");
    const blob = new Blob(
      [
        `User-agent: *\nAllow: /\nSitemap: ${user.attributes["website"]}/sitemap.txt`,
      ],
      {
        type: "text/plain",
      }
    );
    return upload(blob, "", "robots.txt");
  };

  const getSortedUrls = (config: Config) => {
    return config?.pages
      .map((page) => toUrlPath(page.name))
      .concat(config.tours.map((tour) => toUrlPath(tour.name)))
      .concat(config.audibles.map((audible) => toUrlPath(audible.name)))
      .concat(config.blogs.map((blog) => toUrlPath(blog.name)))
      .sort((a, b) => (a > b ? 1 : -1));
  };

  const uploadManifest = () => {
    console.log("uploadManifest");
    return new Promise<void>((resolve, reject) => {
      if (false) {
        resolve();
      } else {
        const manifest = {
          name: config.companyName,
          short_name: config.companyName,
          icons: [
            {
              src: "images/" + SQUARE_LOGO,
              type: "image/svg+xml",
              sizes: "any",
            },
          ],
          background_color: "#ffffff",
          theme_color: config.primaryColor,
          display: "standalone",
          start_url: "/",
          screenshots: [
            {
              src: "/screenshots/narrow_0.png",
              type: "image/png",
              sizes: "720x1360",
              form_factor: "narrow",
            },
            {
              src: "/screenshots/narrow_1.png",
              type: "image/png",
              sizes: "720x1360",
              form_factor: "narrow",
            },
            {
              src: "/screenshots/narrow_2.png",
              type: "image/png",
              sizes: "720x1360",
              form_factor: "narrow",
            },
            {
              src: "/screenshots/narrow_3.png",
              type: "image/png",
              sizes: "720x1360",
              form_factor: "narrow",
            },
            {
              src: "/screenshots/narrow_4.png",
              type: "image/png",
              sizes: "720x1360",
              form_factor: "narrow",
            },
            {
              src: "/screenshots/wide_1.png",
              type: "image/png",
              sizes: "3840x2160",
              form_factor: "wide",
            },
            {
              src: "/screenshots/wide_2.png",
              type: "image/png",
              sizes: "3840x2160",
              form_factor: "wide",
            },
            {
              src: "/screenshots/wide_3.png",
              type: "image/png",
              sizes: "3840x2160",
              form_factor: "wide",
            },
            {
              src: "/screenshots/wide_4.png",
              type: "image/png",
              sizes: "3840x2160",
              form_factor: "wide",
            },
            {
              src: "/screenshots/wide_5.png",
              type: "image/png",
              sizes: "3840x2160",
              form_factor: "wide",
            },
          ],
        };
        const blob = new Blob([JSON.stringify(manifest)], {
          type: "application/json",
        });
        upload(blob, "", "manifest.json")
          .then((_) => resolve())
          .catch(reject);
      }
    });
  };

  const uploadSitemap = () => {
    console.log("uploadSitemap");
    return new Promise<void>((resolve, reject) => {
      const sortedUrls = getSortedUrls(config);

      if (isEqual(sortedUrls, getSortedUrls(publishedConfig))) {
        resolve();
      } else {
        const blob = new Blob(
          [
            sortedUrls.reduce(
              (acc, current) =>
                acc + `\n${user.attributes["website"]}/${current}`,
              `${user.attributes["website"]}`
            ),
          ],
          {
            type: "text/plain",
          }
        );
        upload(blob, "", "sitemap.txt")
          .then((_) => resolve())
          .catch(reject);
      }
    });
  };

  async function toBase64(url): Promise<string> {
    const result = await fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise<string>((resolve) => {
            let reader = new FileReader();
            reader.onload = function () {
              resolve(this.result.toString());
            };
            reader.readAsDataURL(blob);
          })
      );
    return result;
  }

  const [spinning, setSpinning] = useState(false);

  const upload = async (file, path, key?) => {
    console.log("UPLOAD", path + key, file.type);
    return new Promise<string>((resolve, reject) => {
      const headers = {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        mime: file.type,
        path,
      };

      if (key) {
        headers["key"] = key;
      }

      fetch(`${environment.baseUrl}/api/upload`, { headers })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          const options = {
            method: "PUT",
            body: file,
            headers: {
              "Content-Type": file.type,
              "Cache-Control": "max-age=31536000",
            },
          };

          return fetch(res.uploadUrl, options).then(() => {
            resolve(res.key);
          });
        })
        .catch(reject);
    });
  };

  const getImgUrl = (key: string, width: number, height: number) => {
    return `${environment.baseUrlImager}/${user.username}/images/${key}?format=webp&width=${width}&height=${height}&quality=${config?.imgQuality}`;
  };

  const getAudioUrl = (key: string) => {
    return `${user.attributes["website"]}/audios/${key}`;
  };

  const remove = (key) => {
    config.pages.forEach((page) =>
      page.components.forEach((component) => {
        if (component.type === ComponentType.PRODUCTS) {
          component.elements = component.elements.filter((pId) => pId !== key);
        }
      })
    );
    if (key.charAt(0) === "T") {
      config.tours = config.tours.filter((tour) => tour.sId !== key);
    } else if (key.charAt(0) === "A") {
      config.audibles = config.audibles.filter(
        (audible) => audible.sId !== key
      );
    } else if (key.charAt(0) === "B") {
      config.blogs = config.blogs.filter((blog) => blog.sId !== key);
    }

    setConfig({
      ...config,
    });
  };

  const create = (product) => {
    switch (product.sId.charAt(0)) {
      case "T":
        config.tours.push(product);
        setConfig({
          ...config,
          tours: [...config.tours],
        });
        break;
      case "A":
        config.audibles.push(product);
        setConfig({
          ...config,
          audibles: [...config.audibles],
        });
        break;
      case "B":
        config.blogs.push(product);
        setConfig({
          ...config,
          blogs: [...config.blogs],
        });
        break;
      case "S":
        return fetch(`${environment.baseUrl}/api/slot`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
            ...product,
          },
        });
    }
  };

  const updateSlot = (slotId, deltaSeats, deltaPrice) => {
    return fetch(`${environment.baseUrl}/api/slot/${slotId}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        "delta-seats": deltaSeats,
        "delta-price": deltaPrice,
      },
    });
  };

  const update = (product) => {
    switch (product.sId.charAt(0)) {
      case "T":
        setConfig({
          ...config,
          tours: config.tours.map((tour) =>
            tour.sId === product.sId ? product : tour
          ),
        });
        break;
      case "A":
        setConfig({
          ...config,
          audibles: config.audibles.map((audible) =>
            audible.sId === product.sId ? product : audible
          ),
        });
        break;
      case "B":
        setConfig({
          ...config,
          blogs: config.blogs.map((blog) =>
            blog.sId === product.sId ? product : blog
          ),
        });
        break;
    }
  };

  const initializeConfig = (withSamples) => {
    console.log("initializeConfig", withSamples);
    setSpinning(true);

    uploadMainJs()
      .then(uploadFactory)
      .then(uploadDefaultImg)
      .then(uploadLogoFull)
      .then(uploadLogoCompact)
      .then(uploadLogoGhost)
      .then(uploadMapPin)
      .then(uploadRobots)
      .then(() => {
        fetch("../client/config.json")
          .then((res) => res.json())
          .then(async (defaultConfig) => {
            defaultConfig.tId = user.username;
            defaultConfig.apiKey = user.attributes["custom:xApiKey"];
            defaultConfig.baseUrl = environment.baseUrl;
            defaultConfig.baseUrlImager = environment.baseUrlImager;
            defaultConfig.imgQuality = 80;
            defaultConfig.primaryColor = COLORS[0].value;
            defaultConfig.textColor = COLORS[0].text;
            defaultConfig.primaryDarkColor = COLORS[0].value;
            defaultConfig.fontSize = FontSize.Medium;
            defaultConfig.cardSize = CardSize.Medium;
            defaultConfig.borderRadius = BorderRadius.Medium;
            defaultConfig.primaryFont = FONTS[0];
            defaultConfig.secondaryFont = FONTS[0];

            for (let i = 0; i < defaultConfig.tours.length; i++) {
              const tour = defaultConfig.tours[i];

              const today = new Date();
              const todaySlot = today.toISOString().substring(0, 10);

              await create({
                seats: 10,
                price: 3800,
                sId: "S" + tour.sId + todaySlot + "T11:00",
              });

              await create({
                seats: 20,
                price: 4300,
                sId: "S" + tour.sId + todaySlot + "T13:00",
              });

              today.setDate(today.getDate() + 1);
              const tomorrowSlot = today.toISOString().substring(0, 10);

              await create({
                seats: 10,
                price: 5000,
                sId: "S" + tour.sId + tomorrowSlot + "T15:00",
              });

              await create({
                seats: 20,
                price: 5800,
                sId: "S" + tour.sId + tomorrowSlot + "T17:00",
              });
            }

            setConfig(defaultConfig);
            publishVersion();
          });
      });
  };

  const publishVersion = () => {
    setVersion(new Date().toISOString().substring(0, 19).replaceAll(":", "-"));
  };

  console.log("config", config);
  console.log("firstRun", isFirstRun);
  console.log("publishedConfig", publishedConfig);

  return (
    <Routes>
      <Route
        element={
          <Layout style={{ backgroundColor: "white", paddingTop: 70 }}>
            <Flex id="nav" align="center">
              <img
                src={LogoInverted}
                width={40}
                height={40}
                style={{ alignSelf: "center", margin: 10 }}
              />
              <Space style={{ flex: 1 }}>
                {config && (
                  <a
                    className="live-link"
                    target={"_blank"}
                    href={user.attributes["website"]}
                  >
                    <Typography.Text>
                      {user.attributes["website"]}
                    </Typography.Text>
                    <span />
                  </a>
                )}

                {!isEqual(config, publishedConfig) && (
                  <Popconfirm
                    title={"Publish all pending changes"}
                    onConfirm={publishVersion}
                  >
                    <Button type="primary" size="small">
                      Publish
                    </Button>
                  </Popconfirm>
                )}

                {!isEqual(config, publishedConfig) && (
                  <Popconfirm
                    title={"Discard all pending changes"}
                    onConfirm={discardChanges}
                  >
                    <Button danger size="small">
                      <CloseCircleFilled />
                    </Button>
                  </Popconfirm>
                )}
              </Space>
              {config && (
                <NavLink
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  end
                  to="/admin"
                >
                  <ScheduleOutlined />
                  <Typography.Text>Schedule</Typography.Text>
                </NavLink>
              )}

              {config && (
                <NavLink
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  to="/admin/tours"
                >
                  <FireOutlined />
                  <Typography.Text>Tours</Typography.Text>
                </NavLink>
              )}
              {config && (
                <NavLink
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  to="/admin/audibles"
                >
                  <CustomerServiceOutlined />
                  <Typography.Text>Audibles</Typography.Text>
                </NavLink>
              )}

              {config && (
                <NavLink
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  to="/admin/blogs"
                >
                  <AlignLeftOutlined />
                  <Typography.Text>Blogs</Typography.Text>
                </NavLink>
              )}

              {config && (
                <NavLink
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  to="/admin/pages"
                >
                  <WindowsOutlined />
                  <Typography.Text>Pages</Typography.Text>
                </NavLink>
              )}

              {/* {config && (
                <NavLink
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  to="/admin/styles"
                >
                  <BgColorsOutlined />
                  <Typography.Text>Styles</Typography.Text>
                </NavLink>
              )} */}

              {config && (
                <NavLink
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  to="/admin/transactions"
                >
                  <EuroOutlined />
                  <Typography.Text>Transactions</Typography.Text>
                </NavLink>
              )}

              {config && (
                <NavLink
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  to="/admin/settings"
                >
                  <SettingOutlined />
                  <Typography.Text>Settings</Typography.Text>
                </NavLink>
              )}

              <a onClick={signOut}>
                <UnlockOutlined />
                <Typography.Text>Logout</Typography.Text>
              </a>
            </Flex>

            <Typography.Text
              style={{
                fontSize: "small",
                position: "fixed",
                left: 4,
                top: 0,
                color: "#666",
                zIndex: 2,
              }}
            >
              EARLY ACCESS ALPHA {user.attributes["custom:plan"]}{" "}
              {user.attributes["custom:status"]}
            </Typography.Text>

            <Spin spinning={spinning}>
              <Outlet />
            </Spin>
          </Layout>
        }
      >
        <Route
          index
          element={
            config ? (
              <Schedule
                tId={user.username}
                apiKey={user.attributes["custom:xApiKey"]}
                tours={config.tours}
                setSpinning={setSpinning}
                updateSlot={updateSlot}
                create={create}
                accountId={user.attributes["custom:accountId"]}
              />
            ) : isFirstRun ? (
              <GetStarted initConfig={initializeConfig} />
            ) : (
              <WaitingDeployment />
            )
          }
        />

        {config && (
          <Route
            path={"tours"}
            element={
              <Tours
                tours={config.tours}
                isEditor={true}
                select={() => {}}
                create={create}
                remove={remove}
                update={update}
                upload={upload}
                getImgUrl={getImgUrl}
              />
            }
          />
        )}

        {config && (
          <Route
            path={"audibles"}
            element={
              <Audibles
                audibles={config.audibles}
                isEditor={true}
                select={() => {}}
                create={create}
                update={update}
                remove={remove}
                upload={upload}
                getImgUrl={getImgUrl}
                getAudioUrl={getAudioUrl}
              />
            }
          />
        )}

        {config && (
          <Route
            path={"blogs"}
            element={
              <Pages
                upload={upload}
                getImgUrl={getImgUrl}
                getAudioUrl={getAudioUrl}
                setSpinning={setSpinning}
                create={create}
                remove={remove}
                update={update}
                pages={config.blogs}
                config={config}
                setPages={(pages) => {
                  console.log(JSON.stringify(pages));
                  setConfig({ ...config, blogs: pages });
                }}
              />
            }
          />
        )}

        {config && (
          <Route
            path={"pages"}
            element={
              <Pages
                upload={upload}
                getImgUrl={getImgUrl}
                getAudioUrl={getAudioUrl}
                setSpinning={setSpinning}
                create={create}
                remove={remove}
                update={update}
                pages={config.pages}
                config={config}
                setPages={(pages) => setConfig({ ...config, pages: pages })}
              />
            }
          />
        )}

        {/* {config && (
          <Route
            path={"styles"}
            element={
              <PreviewWebsite
                website={user.attributes["website"]}
                setConfig={setConfig}
                config={config}
              />
            }
          />
        )} */}

        {config && (
          <Route
            path={"settings"}
            element={
              <Settings
                user={user}
                upload={upload}
                openBilling={openBilling}
                config={config}
                setConfig={setConfig}
                getImgUrl={getImgUrl}
              />
            }
          />
        )}

        {config && (
          <Route
            path={"transactions"}
            element={
              <Transactions
                setSpinning={setSpinning}
                accountId={user.attributes["custom:accountId"]}
                jwtToken={user.signInUserSession.idToken.jwtToken}
              />
            }
          />
        )}

        {config && (
          <Route
            path={"*"}
            element={
              <Row justify={"center"}>
                <Typography.Title>
                  <FireOutlined />
                  Page Not Found
                </Typography.Title>
              </Row>
            }
          />
        )}
      </Route>
    </Routes>
  );
}

export default App;
