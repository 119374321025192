import { Card, Col, Row, Typography } from "antd";
export const GetStarted = ({ initConfig }) => {
  return (
    <Col>
      <Row justify={"center"} style={{ marginTop: 100 }}>
        <Typography.Title>Get Started</Typography.Title>
      </Row>
      <Row justify={"center"}>
        <Typography.Text>Pick one from the following options</Typography.Text>
      </Row>

      <Row justify={"center"}>
        <Card style={{ width: 350, margin: 40 }}>
          <Col>
            <Row>
              <Typography.Text>
                Sample webpage with a fake product.
              </Typography.Text>
            </Row>

            <Row>
              <Typography.Text>
                You can add, edit or remove all elements from this sample.
              </Typography.Text>
            </Row>

            <Row>
              <Typography.Title>
                <Row>
                  <Typography.Link onClick={() => initConfig(true)}>
                    Sample Data
                  </Typography.Link>
                </Row>
              </Typography.Title>
            </Row>
          </Col>
        </Card>
        <Card style={{ width: 350, margin: 40 }}>
          <Col>
            <Row>
              <Typography.Text>
                This procedure will bootstrap an empty webpage without any
                products.
              </Typography.Text>
            </Row>

            <Row>
              <Typography.Title>
                <Row>
                  <Typography.Link onClick={() => initConfig(false)}>
                    Empty Website
                  </Typography.Link>
                </Row>
              </Typography.Title>
            </Row>
          </Col>
        </Card>
      </Row>
    </Col>
  );
};
