import { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";
import {
  CloseCircleFilled,
  LeftCircleFilled,
  RightCircleFilled,
} from "@ant-design/icons";

import PageEditor from "./PageEditor";
import { NO_INDEX } from "./Utils";

export default function Pages({
  upload,
  create,
  remove,
  update,
  getImgUrl,
  getAudioUrl,
  setSpinning,
  pages,
  setPages,
  config,
}) {
  const [activeKey, setActiveKey] = useState<string>();
  const [titleEditIndex, setTitleEditIndex] = useState(NO_INDEX);
  useEffect(() => {
    let activePageIndex = pages.findIndex((page) => page.name === activeKey);
    setActiveKey(
      pages[activePageIndex === NO_INDEX ? 0 : activePageIndex]?.name
    );
  }, [pages]);

  return (
    <>
      <Row justify={"end"} style={{ padding: 10 }}>
        <Button
          type={"primary"}
          size={"small"}
          title={"Add Page"}
          onClick={() => {
            let newSid = "";
            if (pages.length > 0) {
              const lastValue = pages[pages.length - 1].sId;
              newSid +=
                lastValue.charAt(0) +
                String(Number(lastValue.slice(1)) + 1).padStart(3, "0");
            } else {
              newSid = "B000";
            }

            pages.push({
              sId: newSid,
              name: newSid,
              components: [],
            });
            setPages(pages);
            setActiveKey(newSid);
          }}
        >
          Add Page
        </Button>
      </Row>
      <Tabs
        className="tab"
        items={[
          ...pages.map((page, index) => ({
            label: (
              <Flex justify="center" style={{ minWidth: "200px" }}>
                <Typography.Text
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    if (index !== 0 && activeKey === page.name) {
                      console.log(activeKey);
                      setTitleEditIndex(index);
                    }
                  }}
                >
                  {page.name}
                </Typography.Text>
                {index !== 0 && activeKey === page.name && (
                  <div
                    style={{
                      position: "absolute",
                      right: 0,
                      left: 0,
                      top: 0,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {index > 1 && (
                      <LeftCircleFilled
                        onClick={() => {
                          const temp = pages[index];
                          pages[index] = pages[index - 1];
                          pages[index - 1] = temp;
                          setPages(pages);
                        }}
                      />
                    )}

                    <Popconfirm
                      title={"Remove this page with all contents"}
                      onConfirm={() => {
                        pages = pages.filter((p, i) => i != index);
                        setPages(pages);
                        setActiveKey(pages[0].name);
                      }}
                    >
                      <CloseCircleFilled style={{ color: "red" }} />
                    </Popconfirm>

                    {index < pages.length - 1 && (
                      <RightCircleFilled
                        onClick={() => {
                          const temp = pages[index];
                          pages[index] = pages[index + 1];
                          pages[index + 1] = temp;
                          setPages(pages);
                        }}
                      />
                    )}
                  </div>
                )}
              </Flex>
            ),
            key: page.name,
            children: (
              <PageEditor
                primaryColor={config.primaryColor}
                page={page}
                setPage={(p) => {
                  pages[index] = p;
                  setPages(pages);
                }}
                create={create}
                update={update}
                remove={remove}
                upload={upload}
                getImgUrl={getImgUrl}
                getAudioUrl={getAudioUrl}
                tours={config.tours}
                audibles={config.audibles}
                pages={config.pages}
                blogs={config.blogs}
                setSpinning={setSpinning}
              />
            ),
          })),
        ]}
        onChange={(activeKey) => {
          if (activeKey !== "AddNewPage") {
            setActiveKey(activeKey);
          }
        }}
        activeKey={activeKey}
      />

      <Modal
        open={titleEditIndex !== NO_INDEX}
        closable={false}
        footer={null}
        onCancel={() => setTitleEditIndex(NO_INDEX)}
        onOk={() => {}}
      >
        <Space direction={"vertical"}>
          <Typography.Text strong>Page Name</Typography.Text>
          <Input
            value={pages[titleEditIndex]?.name}
            onChange={(e) => {
              console.log("onChange", e.target.value);
              pages[titleEditIndex].name = e.target.value;
              setActiveKey(e.target.value);
              setPages(pages);
            }}
          />
        </Space>
      </Modal>
    </>
  );
}
