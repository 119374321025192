import { Col, Row, Spin, Typography } from "antd";

export const WaitingDeployment = () => {
  return (
    <Col>
      <Row justify={"center"} style={{ marginTop: 100 }}>
        <Typography.Title>Welcome</Typography.Title>
      </Row>

      <Row justify={"center"} style={{ marginTop: 40 }}>
        <Spin spinning={true} />
      </Row>
    </Col>
  );
};
