import { Button, Col, Flex, Row, Typography } from "antd";
import { useState } from "react";
import ProductCard from "./ProductCard";
import AudibleEditor from "./AudibleEditor";
import { DEFAULT_IMG, NO_INDEX } from "./Utils";

export default function Audibles({
  audibles,
  remove,
  upload,
  create,
  update,
  select,
  getImgUrl,
  getAudioUrl,
  isEditor,
}) {
  const [editorIndex, setEditorIndex] = useState(NO_INDEX);

  console.log("REDER AUDIBLES", audibles);
  return (
    <Col>
      {isEditor && (
        <Row justify={"end"} style={{ padding: 10 }}>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              const id =
                "A" +
                (
                  parseInt(
                    audibles.length > 0
                      ? audibles[audibles.length - 1].sId.substring(1)
                      : 0
                  ) + 1
                )
                  .toString(10)
                  .padStart(3, "0");
              create({
                sId: id,
                name: "New Audible " + parseInt(id.substring(1)),
                legend: DEFAULT_IMG,
                chapters: [],
                slides: [],
              });
              setEditorIndex(audibles.length - 1);
            }}
          >
            New Audible
          </Button>
        </Row>
      )}
      <Flex>
        {audibles?.map((product, index) => (
          <ProductCard
            onClick={() => {
              if (isEditor) {
                setEditorIndex(index);
              } else {
                select(product);
              }
            }}
            img={getImgUrl(product.slides[0]?.img || DEFAULT_IMG)}
            name={"🔈 " + product.name}
            onDelete={isEditor ? () => remove(product.sId) : undefined}
          />
        ))}
      </Flex>
      {editorIndex > NO_INDEX && (
        <AudibleEditor
          onClose={() => {
            setEditorIndex(NO_INDEX);
          }}
          audible={audibles[editorIndex]}
          upload={upload}
          getImgUrl={getImgUrl}
          getAudioUrl={getAudioUrl}
          update={update}
        />
      )}
    </Col>
  );
}
