import {
  Badge,
  Button,
  Col,
  Flex,
  message,
  Modal,
  Popover,
  Radio,
  Row,
  Space,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { Interval, Session, Slot } from "./domain/domain";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FireOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import SlotCreator from "./SlotCreator";
import CheckoutSession from "./CheckoutSession";
import { environment } from "./environments/environment";
import useWebSocket, { ReadyState } from "react-use-websocket";

/**
 * Converts a day number to a string.
 *
 * @param {Number} dayIndex
 * @return {String} Returns day as string
 */
function dayOfWeekAsString(dayIndex) {
  return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][dayIndex % 7];
}

function monthAsString(monthIndex) {
  return [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ][monthIndex];
}

export default function Schedule({
  tId,
  apiKey,
  setSpinning,
  updateSlot,
  create,
  tours,
  accountId,
}) {
  const [isSlotCreatorVisible, setSlotCreatorVisible] = useState(false);
  const [calendarWindow, setCalendarWindow] = useState(Interval.SIX);
  const [offset, setOffset] = useState(0);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    const today = new Date();
    const days = [];
    for (let i = 0; i < calendarWindow; i++) {
      days.push(i);
    }
    setDates(
      days.map((index) => {
        const day = new Date();
        day.setDate(today.getDate() + index + offset);
        return day;
      })
    );
  }, [calendarWindow, offset]);

  const WS_URL = `wss://${environment.webSocketApiId}.execute-api.${environment.region}.amazonaws.com/prod/?t-id=${tId}`;
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    WS_URL,
    {
      share: false,
      retryOnError: true,
      shouldReconnect: (closeEvent) => true,
      heartbeat: true,
    }
  );

  // Run when the connection state (readyState) changes
  useEffect(() => {
    console.log(
      new Date().toLocaleTimeString(),
      "readyState",
      ReadyState[readyState]
    );
  }, [readyState]);

  // Run when a new WebSocket message is received (lastJsonMessage)
  useEffect(() => {
    console.log(`Got a new message: ${JSON.stringify(lastJsonMessage)}`);
    fetchData();
  }, [lastJsonMessage]);

  const [slots, setSlots] = useState<Slot[]>([]);
  const [sessionId, setSessionId] = useState<string>();
  useEffect(() => {
    if (sessionId) {
      setPriceId(undefined);
      setDeltaPrice(0);
      setSeatId(undefined);
      setDeltaSeat(0);
    }
  }, [sessionId]);
  const [seatId, setSeatId] = useState<string>();
  useEffect(() => {
    if (seatId) {
      setSessionId(undefined);
      setPriceId(undefined);
      setDeltaPrice(0);
    }
  }, [seatId]);

  const [deltaSeat, setDeltaSeat] = useState<number>(0);
  const [priceId, setPriceId] = useState<string>();
  useEffect(() => {
    if (priceId) {
      setSessionId(undefined);
      setSeatId(undefined);
      setDeltaSeat(0);
    }
  }, [priceId]);
  const [deltaPrice, setDeltaPrice] = useState<number>(0);
  const [checkoutSessions, setCheckoutSessions] = useState<Session[]>([]);
  const fetchData = () => {
    Promise.all([
      fetch(`${environment.baseUrl}/api/slot/S`, {
        headers: {
          tId,
          "x-api-key": apiKey,
        },
      }).then((res) => res.json()),
      fetch(`${environment.baseUrl}/api/slot/C`, {
        headers: {
          tId,
          "x-api-key": apiKey,
        },
      }).then((res) => res.json()),
    ])
      .then((responses) => {
        console.log(responses);
        setSlots(responses[0].slots);
        setCheckoutSessions(responses[1].slots);
      })
      .catch((err) => message.error(err.message))
      .finally(() => setSpinning(false));
  };

  const publishDeltaSeat = () => {
    updateSlot(seatId, deltaSeat, deltaPrice).then(async (res) => {
      if (res.status === 200) {
        setDeltaSeat(0);
        setSeatId(undefined);
        const updated = await res.json();
        let targetSlot = slots.find((slot) => slot.sId === updated.sId);
        targetSlot.seats = updated.seats;
        targetSlot.price = updated.price;
        console.log("updateSlotAfter", JSON.stringify(slots));
        setSlots([...slots]);
      }
    });
  };

  const publishDeltaPrice = () => {
    updateSlot(priceId, deltaSeat, deltaPrice).then(async (res) => {
      if (res.status === 200) {
        setDeltaPrice(0);
        setPriceId(undefined);
        const updated = await res.json();
        let targetSlot = slots.find((slot) => slot.sId === updated.sId);
        targetSlot.seats = updated.seats;
        targetSlot.price = updated.price;
        console.log("updateSlotAfter", JSON.stringify(slots));
        setSlots([...slots]);
      }
    });
  };

  const markAsCompleted = () => {};

  const expireSession = () => {};

  return (
    <div
      onClick={() => {
        setSessionId(undefined);
        setPriceId(undefined);
        setSeatId(undefined);
      }}
    >
      <Row justify={"space-between"} style={{ padding: 10 }}>
        <Space>
          <Button size={"small"} onClick={fetchData}>
            Refresh
          </Button>
          <Badge
            status={readyState === ReadyState.OPEN ? "success" : "error"}
            text={ReadyState[readyState]}
          />
        </Space>

        <Space>
          <ArrowLeftOutlined
            onClick={() => {
              setOffset((prevState) => prevState - 1);
            }}
          />
          <Radio.Group
            size={"small"}
            defaultValue={calendarWindow}
            buttonStyle="solid"
            onChange={(e) => setCalendarWindow(e.target.value)}
          >
            {Object.values(Interval)
              .filter((option) => !isNaN(Number(option)))
              .map((option) => (
                <Radio.Button key={option} value={option}>
                  {option}
                </Radio.Button>
              ))}
          </Radio.Group>
          <ArrowRightOutlined
            onClick={() => {
              setOffset((prevState) => prevState + 1);
            }}
          />
        </Space>

        <Button
          type={"primary"}
          size={"small"}
          onClick={() => {
            if (accountId) {
              setSlotCreatorVisible(true);
            } else {
              message.info(
                "Payment integration is missing, go to Transactions to setup Stripe Payments"
              );
            }
          }}
        >
          Add New Slot
        </Button>
      </Row>

      <Row>
        <Col span={1}></Col>
        <Col span={22}>
          <Row style={{ backgroundColor: "lightgrey" }}>
            {dates.map((date, index) => {
              let startDate = new Date(date.getFullYear(), 0, 1);
              // @ts-ignore
              let days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
              let weekNumber = Math.ceil(days / 7);
              return (
                <Col
                  key={index}
                  span={Math.floor(24 / calendarWindow)}
                  style={{
                    paddingLeft: 4,
                    border: "1px gray solid",
                    borderLeft: index === 0 ? "1px gray solid" : "none",
                  }}
                >
                  <p style={{ margin: 0, fontSize: "x-small" }}>
                    {dayOfWeekAsString(date.getDay())}
                  </p>
                  <Typography.Title
                    level={4}
                    style={{ margin: 0, textAlign: "center" }}
                  >
                    {date.getDate()}
                  </Typography.Title>
                  <p
                    style={{
                      margin: 0,
                      fontSize: "x-small",
                      textAlign: "center",
                    }}
                  >
                    {monthAsString(date.getMonth())}
                  </p>
                </Col>
              );
            })}
          </Row>
        </Col>
        <Col span={1}></Col>
      </Row>
      {dates.length > 0 &&
        tours.map((tour) => {
          const currentTourCheckoutSessions = checkoutSessions.filter(
            (checkoutSession) => checkoutSession.sId.startsWith("C" + tour.sId)
          );
          const currentTourSlots = slots?.filter((s) =>
            s.sId.startsWith("S" + tour.sId)
          );
          const rangeStart = dates[0].toISOString().substring(0, 10);
          const rangeEnd = dates[dates.length - 1]
            .toISOString()
            .substring(0, 10);
          const currentRangeSlots = currentTourSlots.filter(
            (s) =>
              s.sId.substring(5, 15) >= rangeStart &&
              s.sId.substring(5, 15) <= rangeEnd
          );

          return currentRangeSlots.length ? (
            <Row>
              <Col span={1}>
                <Popover
                  content={
                    <Flex vertical>
                      <Typography.Text>
                        <FireOutlined />
                        {tour.sId}
                      </Typography.Text>
                      <Typography.Text>{tour.name}</Typography.Text>
                    </Flex>
                  }
                >
                  <Row
                    justify={"center"}
                    align={"middle"}
                    style={{ cursor: "pointer" }}
                  >
                    <FireOutlined />
                    <Typography.Text style={{ fontSize: "smaller" }}>
                      {tour.sId}
                    </Typography.Text>
                  </Row>
                </Popover>
              </Col>
              <Col span={22}>
                <Row>
                  {dates.map((date, index) => {
                    return (
                      <Col
                        style={{
                          border: "1px gray solid",
                          borderTop: "none",
                          borderLeft: index === 0 ? "1px gray solid" : "none",
                        }}
                        onClick={() => {
                          console.log("onClick", date, tour.name);
                        }}
                        span={24 / calendarWindow}
                      >
                        <Flex wrap="wrap">
                          {currentRangeSlots
                            .filter((s) =>
                              s.sId.includes(
                                date.toISOString().substring(0, 10)
                              )
                            )
                            .map((s, index) => {
                              const currentTourCurrentSlotCheckoutSessions =
                                currentTourCheckoutSessions.filter(
                                  (checkoutSession) =>
                                    checkoutSession.sId.startsWith(
                                      "C" + s.sId.substring(1)
                                    )
                                );
                              // @ts-ignore
                              return (
                                <Col
                                  span={2 * calendarWindow}
                                  style={{ padding: 2 }}
                                >
                                  <Flex
                                    justify="space-between"
                                    align="center"
                                    wrap="wrap"
                                    style={{
                                      backgroundColor: "#999",
                                      borderRadius: 4,
                                    }}
                                  >
                                    <Typography.Text
                                      style={{
                                        margin: 2,
                                        marginRight: 0,
                                        color: "white",
                                        fontFamily: "monospace",
                                      }}
                                    >
                                      {s.sId.substring(16)}
                                    </Typography.Text>

                                    <Popover
                                      key={"price_popover_" + s.sId}
                                      placement={"bottom"}
                                      open={priceId === s.sId}
                                      content={
                                        <Space
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                          style={{
                                            width: 80,
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <MinusCircleOutlined
                                            onClick={() => {
                                              setDeltaPrice(
                                                (deltaPrice) => deltaPrice - 100
                                              );
                                            }}
                                          />
                                          <Button
                                            disabled={deltaPrice === 0}
                                            type={"primary"}
                                            size={"small"}
                                            onClick={publishDeltaPrice}
                                          >
                                            {deltaPrice / 100}
                                          </Button>

                                          <PlusCircleOutlined
                                            onClick={() => {
                                              console.log(
                                                "deltaPrice",
                                                deltaPrice
                                              );
                                              setDeltaPrice(
                                                (deltaPrice) => deltaPrice + 100
                                              );
                                            }}
                                          />
                                        </Space>
                                      }
                                    >
                                      <Typography.Text
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setPriceId(s.sId);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          color: "#666",
                                          fontFamily: "monospace",
                                          fontSize: "xx-small",
                                        }}
                                      >
                                        €
                                      </Typography.Text>
                                      <Typography.Text
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setPriceId(s.sId);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          color: "#666",
                                          fontFamily: "monospace",
                                        }}
                                      >
                                        {s.price / 100}
                                      </Typography.Text>
                                    </Popover>

                                    <Popover
                                      key={"saet_popover_" + s.sId}
                                      placement={"bottom"}
                                      open={seatId === s.sId}
                                      content={
                                        <Space
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                          style={{
                                            width: 80,
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <MinusCircleOutlined
                                            onClick={() => {
                                              const slot = slots.find(
                                                (slot) => slot.sId === seatId
                                              );
                                              if (slot.seats + deltaSeat > 0) {
                                                setDeltaSeat(
                                                  (deltaSeat) => deltaSeat - 1
                                                );
                                              }
                                            }}
                                          />
                                          <Button
                                            disabled={deltaSeat === 0}
                                            type={"primary"}
                                            size={"small"}
                                            onClick={publishDeltaSeat}
                                          >
                                            {deltaSeat}
                                          </Button>

                                          <PlusCircleOutlined
                                            onClick={() => {
                                              setDeltaSeat(
                                                (deltaSeat) => deltaSeat + 1
                                              );
                                            }}
                                          />
                                        </Space>
                                      }
                                    >
                                      <Typography.Text
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setSeatId(s.sId);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          margin: 2,
                                          marginLeft: 0,
                                          color: "white",
                                          fontFamily: "monospace",
                                        }}
                                      >
                                        {s.seats || "-"}
                                      </Typography.Text>
                                    </Popover>
                                  </Flex>
                                  <Flex wrap="wrap">
                                    {currentTourCurrentSlotCheckoutSessions.map(
                                      (currentSession) => (
                                        <Col
                                          span={6}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setSessionId(currentSession.sId);
                                          }}
                                        >
                                          <CheckoutSession
                                            checkoutSession={currentSession}
                                            isOpen={
                                              sessionId === currentSession.sId
                                            }
                                            markAsCompleted={markAsCompleted}
                                            expireSession={expireSession}
                                          />
                                        </Col>
                                      )
                                    )}
                                  </Flex>
                                </Col>
                              );
                            })}
                        </Flex>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col span={1}>
                <Popover
                  content={
                    <Flex vertical>
                      <Typography.Text>
                        <FireOutlined />
                        {tour.sId}
                      </Typography.Text>
                      <Typography.Text>{tour.name}</Typography.Text>
                    </Flex>
                  }
                >
                  <Row
                    justify={"center"}
                    align={"middle"}
                    style={{ cursor: "pointer" }}
                  >
                    <FireOutlined />
                    <Typography.Text style={{ fontSize: "smaller" }}>
                      {tour.sId}
                    </Typography.Text>
                  </Row>
                </Popover>
              </Col>
            </Row>
          ) : null;
        })}
      <Modal
        title={"New Slot"}
        open={isSlotCreatorVisible}
        onCancel={() => setSlotCreatorVisible(false)}
        footer={null}
        closable={false}
      >
        <SlotCreator
          tours={tours}
          onFinish={(values) => {
            setSlotCreatorVisible(false);
            const newId =
              "S" + values.sId + values.date.format("YYYY-MM-DDThh:mm");
            if (slots.find((s) => s.sId === newId)) {
              message.warning(
                "The Slot already exist. Use the editor feature on the slot."
              );
            } else {
              setSpinning(true);
              const newSlot = {
                sId: newId,
                seats: values.pax,
                price: values.price * 100,
              };
              create(newSlot)
                .then(() =>
                  setSlots(
                    [...slots, newSlot].sort((a, b) => (a.sId > b.sId ? 1 : -1))
                  )
                )
                .finally(() => setSpinning(false));
            }
          }}
        />
      </Modal>
    </div>
  );
}
