import ReactDOM from "react-dom/client";
import "./index.css";
import { environment } from "./environments/environment";
import { Amplify } from "aws-amplify";

import { Cognito } from "./Cognito";
import { loadStripe } from "@stripe/stripe-js";
import { BrowserRouter } from "react-router-dom";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const awsMobile = {
  Auth: {
    region: environment.userPoolId.split("_")[0],
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.userPoolWebClientId,
  },
};
Amplify.configure(awsMobile);
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
export const STRIPE = loadStripe(environment.stripePublishableKey);

root.render(
  <BrowserRouter>
    <Cognito />
  </BrowserRouter>
);
