import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectComponentsProvider,
  ConnectPayments,
} from "@stripe/react-connect-js";
import { useState } from "react";
import StripeLogo from "./assets/stripe.svg";
import { environment } from "./environments/environment";
import { Card, Col, Flex, Row, Typography } from "antd";

export const Transactions = ({ jwtToken, accountId, setSpinning }) => {
  const linkAccount = () => {
    setSpinning();
    fetch(`${environment.baseUrl}/api/link`, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => window.location.replace(data.url));
      }
    });
  };

  // We use `useState` to ensure the Connect instance is only initialized once
  const [stripeConnectInstance] = useState(() => {
    if (accountId) {
      const fetchClientSecret = async () => {
        return fetch(`${environment.baseUrl}/api/session`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }).then((res) => {
          if (res.status === 200) {
            return res.json().then((data) => data.clientSecret);
          }
        });
      };
      return loadConnectAndInitialize({
        // This is your test publishable API key.
        publishableKey: environment.stripePublishableKey,
        fetchClientSecret: fetchClientSecret,
      });
    }
  });
  return accountId ? (
    <div style={{ margin: 20 }}>
      <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
        <ConnectPayments />
      </ConnectComponentsProvider>
    </div>
  ) : (
    <Flex justify="center">
      <Card style={{ width: 350, margin: 40 }}>
        <Col>
          <img src={StripeLogo} />
          <Row>
            <Typography.Text>
              Stripe can help you to get paid quickly and keep your personal and
              payment information secure. Thousands of companies around the
              world trust Stripe to process payments for their users. Set up a
              Stripe account to get payments.
            </Typography.Text>
          </Row>

          <Row>
            <Typography.Title>
              <Row>
                <Typography.Link onClick={linkAccount}>
                  Setup payouts on Stripe
                </Typography.Link>
              </Row>
            </Typography.Title>
          </Row>
        </Col>
      </Card>
    </Flex>
  );
};
