import { Button, Col, Row } from "antd";
import { useState } from "react";
import ProductCard from "./ProductCard";
import { TourEditor } from "./TourEditor";
import { DEFAULT_IMG, NO_INDEX } from "./Utils";

export default function Tours({
  remove,
  upload,
  update,
  select,
  create,
  getImgUrl,
  isEditor,
  tours,
}) {
  const [editorIndex, setEditorIndex] = useState(NO_INDEX);

  const addNewProduct = () => {
    const id =
      "T" +
      (tours.length
        ? parseInt(tours[tours.length - 1].sId.substring(1)) + 1
        : 0
      )
        .toString(10)
        .padStart(3, "0");
    create({
      sId: id,
      name: "New Tour " + parseInt(id.substring(1)),
      images: [DEFAULT_IMG],
      inclusions: [],
      exclusions: [],
      activities: [],
      captions: [],
    });
    setEditorIndex(tours.length - 1);
  };

  return (
    <Col>
      {isEditor && (
        <Row justify={"end"} style={{ padding: 10 }}>
          <Button
            title={"New Tour"}
            size={"small"}
            type={"primary"}
            onClick={addNewProduct}
          >
            New Tour
          </Button>
        </Row>
      )}
      <Row align={"middle"}>
        {tours?.map((product, index) => (
          <ProductCard
            onClick={
              isEditor
                ? () => {
                    setEditorIndex(index);
                  }
                : () => select(product)
            }
            img={getImgUrl(product.images[0])}
            name={product.name}
            desc={product.desc}
            onDelete={
              isEditor
                ? () => {
                    remove(product.sId);
                  }
                : undefined
            }
          />
        ))}
      </Row>
      {editorIndex >= 0 && (
        <TourEditor
          tour={tours[editorIndex]}
          getImgUrl={getImgUrl}
          update={update}
          upload={upload}
          dismiss={() => {
            setEditorIndex(NO_INDEX);
          }}
        />
      )}
    </Col>
  );
}
