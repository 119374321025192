import { Spin } from "antd";
import { useState } from "react";
import { MinusCircleFilled } from "@ant-design/icons";
import { GAP } from "./PageEditor";

export default function ImageInput({
  imageUrl,
  id,
  upload,
  onRemove,
  width = 160,
  height = 120,
  contentType = "image/png, image/jpeg, image/svg+xml, image/webp",
  backgroundColor = "#FFFFFF00",
  borderRadius = 8,
}) {
  const [loading, setLoading] = useState(false);
  return (
    <div
      title="Edit"
      key={id}
      style={{
        position: "relative",
      }}
    >
      <label
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor,
          borderRadius,
          width,
          height,
        }}
        htmlFor={id}
        title="Select another image"
      >
        {loading || (
          <img
            style={{
              cursor: "pointer",
              objectFit: "cover",
              display: "block",
              borderRadius,
              width,
              height,
            }}
            src={imageUrl}
          />
        )}
        <Spin spinning={loading} />
      </label>
      <input
        onChange={(event) => {
          if (event.target.files[0]) {
            setLoading(true);
            upload(event.target.files[0], "images/").finally(() =>
              setLoading(false)
            );
          }
        }}
        style={{ display: "none" }}
        type="file"
        accept={contentType}
        id={id}
      />

      {onRemove && (
        <MinusCircleFilled
          style={{
            color: "red",
            position: "absolute",
            top: -7,
            right: -7,
          }}
          title="Remove"
          onClick={onRemove}
        />
      )}
    </div>
  );
}
