import {
  Button,
  Col,
  Divider,
  Flex,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";

import { ComponentType, InputType } from "./domain/domain";
import {
  CloseCircleFilled,
  DownCircleFilled,
  LeftCircleFilled,
  LinkOutlined,
  PlusOutlined,
  RightCircleFilled,
  UpCircleFilled,
} from "@ant-design/icons";
import { DEFAULT_IMG, NO_INDEX } from "./Utils";
import { useEffect, useState } from "react";
import Tours from "./Tours";
import Audibles from "./Audibles";
import ImageInput from "./ImageInput";

const WIDTH_POSTER = window.innerWidth * 0.6;
const HEIGHT_POSTER = window.innerWidth * 0.3;
export const GAP = 28;
export default function PageEditor({
  primaryColor,
  upload,
  create,
  remove,
  update,
  page,
  setPage,
  getImgUrl,
  getAudioUrl,
  setSpinning,
  tours,
  audibles,
  pages,
  blogs,
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  const [componentIndex, setComponentIndex] = useState(NO_INDEX);
  const [componentKey, setComponentKey] = useState(undefined);
  const [componentSubIndex, setComponentSubIndex] = useState(NO_INDEX);
  const [inputType, setInputType] = useState(InputType.Text);
  const [componentSubKey, setComponentSubKey] = useState(undefined);
  const [componentValue, setComponentValue] = useState(undefined);
  const [activeKey, setActiveKey] = useState("0");

  const [activeComponentIndex, setActiveComponentIndex] = useState(NO_INDEX);
  const [activeElementIndex, setActiveElementIndex] = useState(NO_INDEX);
  const [activeItemIndex, setActiveItemIndex] = useState(NO_INDEX);

  const cancelModal = () => {
    setComponentIndex(NO_INDEX);
    setComponentKey(undefined);
    setComponentValue(undefined);
    setComponentSubIndex(NO_INDEX);
    setComponentSubKey(undefined);
    setInputType(InputType.Text);
  };

  const addSectionButton = (sectionIndex) => {
    return (
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Popover
          trigger={"click"}
          content={
            <Col>
              {Object.values(ComponentType).map((componentType, index) => {
                return (
                  <Row key={index}>
                    <Typography.Link
                      onClick={() => {
                        const firstPart = page.components.slice(
                          0,
                          sectionIndex
                        );
                        const remainingPart =
                          page.components.slice(sectionIndex);
                        let newComponent;

                        switch (componentType) {
                          case ComponentType.INFO:
                            newComponent = {
                              type: ComponentType.INFO,
                              elements: [],
                            };
                            break;
                          case ComponentType.TITLE:
                            newComponent = {
                              type: ComponentType.TITLE,
                              title: "Title Content",
                              level: 2,
                            };
                            break;
                          case ComponentType.PARAGRAPH:
                            newComponent = {
                              type: ComponentType.PARAGRAPH,
                              background: "light",
                              description:
                                "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                            };
                            break;
                          case ComponentType.PRODUCTS:
                            newComponent = {
                              type: componentType,
                              elements: [],
                            };
                            break;

                          case ComponentType.FAQ:
                            newComponent = {
                              type: ComponentType.FAQ,
                              elements: [
                                {
                                  title: "Faq Group 1",
                                  elements: [
                                    {
                                      q: "Sample question?",
                                      a: "Yes, we have the answer.",
                                    },
                                  ],
                                },
                              ],
                            };
                            break;
                          case ComponentType.SPACE:
                            newComponent = {
                              type: ComponentType.SPACE,
                              size: "md",
                            };
                            break;
                        }
                        setPage({
                          ...page,
                          components: [
                            ...firstPart,
                            newComponent,
                            ...remainingPart,
                          ],
                        });
                      }}
                    >
                      {componentType}
                    </Typography.Link>
                  </Row>
                );
              })}
            </Col>
          }
        >
          <PlusOutlined />
        </Popover>
      </div>
    );
  };

  const handleInput = (inputValue) => {
    if (componentSubIndex !== NO_INDEX) {
      page.components[componentIndex][componentKey][componentSubIndex][
        componentSubKey
      ] = inputValue;
      setPage({ ...page });
      cancelModal();
    } else {
      page.components[componentIndex][componentKey] = inputValue;
      setPage({ ...page });
      cancelModal();
    }
  };

  const componentHTML = (component, componentIndex) => {
    switch (component.type) {
      case ComponentType.TITLE:
        return (
          <div>
            <Popover
              trigger={"click"}
              content={
                <input
                  style={{
                    minWidth: 300,
                  }}
                  onChange={(e) => {
                    component.title = e.target.value;
                    setPage({ ...page });
                  }}
                  value={component.title}
                />
              }
            >
              <Typography.Title
                level={component.level}
                style={{ cursor: "pointer" }}
              >
                {component.title}
              </Typography.Title>
            </Popover>
            <Space
              style={{
                position: "absolute",
                left: GAP / 2,
                top: GAP / 2,
              }}
            >
              <Radio.Group
                value={component.level}
                size="small"
                buttonStyle="solid"
                onChange={(e) => {
                  console.log("onChange", e);
                  component.level = e.target.value;
                  setPage({ ...page });
                }}
              >
                <Radio.Button value={1}>h1</Radio.Button>
                <Radio.Button value={2}>h2</Radio.Button>
              </Radio.Group>
            </Space>
          </div>
        );

      case ComponentType.PARAGRAPH:
        return (
          <>
            <Space
              style={{
                position: "absolute",
                top: GAP / 2,
                left: GAP / 2,
              }}
            >
              <Radio.Group
                value={component.background}
                size="small"
                buttonStyle="solid"
                onChange={(e) => {
                  console.log("onChange", e);
                  component.background = e.target.value;
                  setPage({ ...page });
                }}
              >
                <Radio.Button value="light">Light</Radio.Button>
                <Radio.Button value="dark">Dark</Radio.Button>
              </Radio.Group>
            </Space>

            <textarea
              style={{
                width: "90vw",
                minHeight: 300,
                padding: "2rem",
                border: "unset",
                borderRadius: "2rem",
                backgroundColor:
                  component.background === "light" ? "#eee" : primaryColor,
                color: component.background === "dark" ? "#fff" : "#000",
              }}
              value={component.description}
              onChange={(e) => {
                component.description = e.target.value;
                setPage({ ...page });
              }}
            />
          </>
        );

      case ComponentType.PRODUCTS:
        return (
          <>
            <Button
              style={{
                position: "absolute",
                bottom: GAP / 2,
                right: GAP / 2,
              }}
              type={"primary"}
              shape={"circle"}
              icon={<PlusOutlined />}
              onClick={() => {
                setInputType(InputType.Product);
                setComponentIndex(componentIndex);
                setComponentKey("elements");
              }}
            />

            <Flex
              style={{
                padding: 30,
              }}
              justify={"center"}
              align={"middle"}
              wrap="wrap"
            >
              {component.elements &&
                component.elements.map((sId, productIndex) => {
                  const product =
                    tours.find((product) => product.sId === sId) ||
                    audibles.find((product) => product.sId === sId);
                  return (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveComponentIndex(componentIndex);
                        setActiveElementIndex(productIndex);
                      }}
                      key={productIndex}
                      style={{
                        display: "inline-block",
                        position: "relative",
                        width: 244,
                        margin: 14,
                        borderRadius: 22,
                        cursor: "pointer",
                        backgroundColor: "white",
                        textAlign: "center",

                        borderWidth: 2,
                        borderStyle: "solid",
                        borderColor:
                          activeComponentIndex === componentIndex &&
                          activeElementIndex === productIndex
                            ? "#1677ff"
                            : "#ccc",
                      }}
                    >
                      <img
                        src={getImgUrl(
                          product.sId.startsWith("T")
                            ? product.images[0]
                            : product.slides[0].img
                        )}
                        width={240}
                        height={160}
                        style={{
                          borderTopLeftRadius: 20,
                          borderTopRightRadius: 20,
                        }}
                      />
                      <div
                        style={{
                          paddingLeft: 6,
                          paddingRight: 6,
                          paddingBottom: 6,
                        }}
                      >
                        <Row justify={"center"}>
                          <Typography.Text strong>
                            {product.name}
                          </Typography.Text>
                        </Row>

                        <Typography.Text>{product.description}</Typography.Text>
                      </div>

                      {activeComponentIndex === componentIndex &&
                        activeElementIndex === productIndex &&
                        productIndex > 0 && (
                          <LeftCircleFilled
                            title={"Move Left"}
                            onClick={(e) => {
                              e.stopPropagation();
                              component.elements[productIndex] =
                                component.elements[productIndex - 1];
                              component.elements[productIndex - 1] = sId;
                              setActiveElementIndex(productIndex - 1);
                              setPage({ ...page });
                            }}
                            style={{
                              position: "absolute",
                              left: 0,
                              bottom: 0,
                            }}
                          />
                        )}

                      {activeComponentIndex === componentIndex &&
                        activeElementIndex === productIndex &&
                        productIndex < component.elements.length - 1 && (
                          <RightCircleFilled
                            title={"Move Right"}
                            onClick={(e) => {
                              e.stopPropagation();
                              component.elements[productIndex] =
                                component.elements[productIndex + 1];
                              component.elements[productIndex + 1] = sId;
                              setActiveElementIndex(productIndex + 1);
                              setPage({ ...page });
                            }}
                            style={{
                              position: "absolute",
                              right: 0,
                              bottom: 0,
                            }}
                          />
                        )}

                      {activeComponentIndex === componentIndex &&
                        activeElementIndex === productIndex && (
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              backgroundColor: "white",
                              display: "flex",
                              borderRadius: 7,
                            }}
                          >
                            <CloseCircleFilled
                              onClick={() => {
                                component.elements = component.elements.filter(
                                  (p, i) => i !== productIndex
                                );
                                setPage({ ...page });
                              }}
                              title={"Remove"}
                              style={{
                                color: "red",
                              }}
                            />
                          </div>
                        )}
                    </div>
                  );
                })}
            </Flex>
          </>
        );

      case ComponentType.INFO:
        return (
          <Flex wrap="wrap" gap={"2rem"}>
            {component.elements &&
              component.elements.map((element, elementIndex) => {
                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveComponentIndex(componentIndex);
                      setActiveElementIndex(elementIndex);
                    }}
                    key={elementIndex}
                    style={{ position: "relative", alignSelf: "flex-start" }}
                  >
                    <div
                      style={{
                        pointerEvents:
                          activeComponentIndex === componentIndex &&
                          activeElementIndex === elementIndex
                            ? "unset"
                            : "none",
                        borderColor:
                          activeComponentIndex === componentIndex &&
                          activeElementIndex === elementIndex
                            ? "#1677ff"
                            : "#ccc",
                        backgroundColor:
                          element.background === "dark" ? primaryColor : "#eee",
                      }}
                      className={`info ${element.size} ${element.background} ${element.flexDirection}`}
                    >
                      <ImageInput
                        id={
                          page.name +
                          "_infos_" +
                          componentIndex +
                          "_" +
                          elementIndex
                        }
                        imageUrl={getImgUrl(
                          element.img,
                          WIDTH_POSTER * window.devicePixelRatio,
                          HEIGHT_POSTER * window.devicePixelRatio
                        )}
                        upload={(file, path) =>
                          upload(file, path).then((key) => {
                            element.img = key;
                            setPage({ ...page });
                          })
                        }
                        onRemove={undefined}
                        borderRadius={32}
                      />

                      {(element.label || element.title || element.content) && (
                        <div className="info-content">
                          {element.label && (
                            <Popover
                              content={
                                <Input
                                  value={element.label}
                                  onChange={(e) => {
                                    element.label = e.target.value;
                                    setPage({ ...page });
                                  }}
                                />
                              }
                            >
                              <div
                                style={{
                                  position: "relative",
                                  backgroundColor: "#eff",
                                  padding: "1rem",
                                  borderRadius: "2rem",
                                }}
                              >
                                <Typography.Title
                                  style={{
                                    margin: 0,
                                  }}
                                >
                                  {element.label}
                                </Typography.Title>
                                {activeComponentIndex === componentIndex &&
                                  activeElementIndex === elementIndex && (
                                    <CloseCircleFilled
                                      style={{
                                        color: "red",
                                        position: "absolute",
                                        right: 0,
                                        top: 0,
                                      }}
                                      onClick={() => {
                                        delete element.label;
                                        setPage({ ...page });
                                      }}
                                      title={"Remove"}
                                    />
                                  )}
                              </div>
                            </Popover>
                          )}

                          {element.title && (
                            <Popover
                              content={
                                <Input
                                  value={element.title}
                                  onChange={(e) => {
                                    element.title = e.target.value;
                                    setPage({ ...page });
                                  }}
                                />
                              }
                            >
                              <div
                                style={{
                                  position: "relative",
                                  backgroundColor: "#eff",
                                  padding: 8,
                                  borderRadius: "2rem",
                                }}
                              >
                                <Typography.Title
                                  level={2}
                                  style={{ margin: 0 }}
                                >
                                  {element.title}
                                </Typography.Title>
                                {activeComponentIndex === componentIndex &&
                                  activeElementIndex === elementIndex && (
                                    <CloseCircleFilled
                                      style={{
                                        color: "red",
                                        position: "absolute",
                                        right: 0,
                                        top: 0,
                                      }}
                                      onClick={() => {
                                        delete element.title;
                                        setPage({ ...page });
                                      }}
                                      title={"Remove"}
                                    />
                                  )}
                              </div>
                            </Popover>
                          )}

                          {element.content && (
                            <Popover
                              content={
                                <Input.TextArea
                                  style={{
                                    width: 300,
                                    height: 100,
                                  }}
                                  value={element.content}
                                  onChange={(e) => {
                                    element.content = e.target.value;
                                    setPage({ ...page });
                                  }}
                                />
                              }
                            >
                              <div
                                style={{
                                  position: "relative",
                                  backgroundColor: "#eff",
                                  padding: 8,
                                  borderRadius: "2rem",
                                }}
                              >
                                <Typography.Text>
                                  {element.content}
                                </Typography.Text>
                                {activeComponentIndex === componentIndex &&
                                  activeElementIndex === elementIndex && (
                                    <CloseCircleFilled
                                      style={{
                                        color: "red",
                                        position: "absolute",
                                        right: 0,
                                        top: 0,
                                      }}
                                      onClick={() => {
                                        delete element.content;
                                        setPage({ ...page });
                                      }}
                                      title={"Remove"}
                                    />
                                  )}
                              </div>
                            </Popover>
                          )}
                        </div>
                      )}
                    </div>

                    {activeComponentIndex === componentIndex &&
                      activeElementIndex === elementIndex && (
                        <Space direction="vertical" className="options">
                          <Space>
                            <Radio.Group
                              value={element.size}
                              size="small"
                              buttonStyle="solid"
                              onChange={(e) => {
                                console.log("onChange", e);
                                element.size = e.target.value;
                                setPage({ ...page });
                              }}
                            >
                              <Radio.Button value="sm">S</Radio.Button>
                              <Radio.Button value="md">M</Radio.Button>
                              <Radio.Button value="lg">L</Radio.Button>
                              <Radio.Button value="xl">X</Radio.Button>
                            </Radio.Group>

                            {(element.label ||
                              element.title ||
                              element.content) && (
                              <Radio.Group
                                value={element.background}
                                size="small"
                                buttonStyle="solid"
                                onChange={(e) => {
                                  console.log("onChange", e);
                                  element.background = e.target.value;
                                  setPage({ ...page });
                                }}
                              >
                                <Radio.Button value="light">☀</Radio.Button>
                                <Radio.Button value="dark">☾</Radio.Button>
                              </Radio.Group>
                            )}

                            {element.size === "xl" &&
                              (element.label ||
                                element.title ||
                                element.content) && (
                                <Radio.Group
                                  value={element.flexDirection}
                                  size="small"
                                  buttonStyle="solid"
                                  onChange={(e) => {
                                    console.log("onChange", e);
                                    element.flexDirection = e.target.value;
                                    setPage({ ...page });
                                  }}
                                >
                                  <Radio.Button value="row-reverse">
                                    ←
                                  </Radio.Button>
                                  <Radio.Button value="row">→</Radio.Button>
                                </Radio.Group>
                              )}
                          </Space>

                          <Button
                            size="small"
                            type="primary"
                            onClick={() => {
                              setInputType(InputType.Link);
                              setComponentIndex(componentIndex);
                              setComponentKey("elements");
                              setComponentSubIndex(elementIndex);
                              setComponentSubKey("link");
                            }}
                          >
                            <LinkOutlined /> {element.link || "Add Link"}
                          </Button>

                          {!element.label && (
                            <Button
                              size="small"
                              type="primary"
                              onClick={() => {
                                element.label = "Label";
                                setPage({ ...page });
                              }}
                              icon={<PlusOutlined />}
                            >
                              Label
                            </Button>
                          )}

                          {!element.title && (
                            <Button
                              size="small"
                              type="primary"
                              onClick={() => {
                                element.title = "Title";
                                setPage({ ...page });
                              }}
                              icon={<PlusOutlined />}
                            >
                              Title
                            </Button>
                          )}

                          {!element.content && (
                            <Button
                              size="small"
                              type="primary"
                              icon={<PlusOutlined />}
                              onClick={() => {
                                element.content =
                                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam commodo felis sit amet tortor convallis, id fermentum sapien tincidunt.";
                                setPage({ ...page });
                              }}
                            >
                              Content
                            </Button>
                          )}
                        </Space>
                      )}

                    {activeComponentIndex === componentIndex &&
                      activeElementIndex === elementIndex &&
                      elementIndex > 0 && (
                        <div
                          style={{
                            backgroundColor: "white",
                            color: "#4096ff",
                            borderRadius: 7,
                            display: "flex",
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                          }}
                        >
                          <LeftCircleFilled
                            title={"Move Left"}
                            onClick={(e) => {
                              e.stopPropagation();
                              let currentInfo =
                                component.elements[elementIndex];
                              component.elements[elementIndex] =
                                component.elements[elementIndex - 1];
                              component.elements[elementIndex - 1] =
                                currentInfo;
                              setActiveElementIndex(elementIndex - 1);
                              setPage({ ...page });
                            }}
                          />
                        </div>
                      )}

                    {activeComponentIndex === componentIndex &&
                      activeElementIndex === elementIndex &&
                      elementIndex < component.elements.length - 1 && (
                        <div
                          style={{
                            backgroundColor: "white",
                            color: "#4096ff",
                            borderRadius: 7,
                            display: "flex",
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                          }}
                        >
                          <RightCircleFilled
                            title={"Move Right"}
                            onClick={(e) => {
                              e.stopPropagation();
                              let currentInfo =
                                component.elements[elementIndex];
                              component.elements[elementIndex] =
                                component.elements[elementIndex + 1];
                              component.elements[elementIndex + 1] =
                                currentInfo;
                              setActiveElementIndex(elementIndex + 1);
                              setPage({ ...page });
                            }}
                          />
                        </div>
                      )}

                    {activeComponentIndex === componentIndex &&
                      activeElementIndex === elementIndex && (
                        <Popconfirm
                          title={"Remove the card"}
                          onConfirm={() => {
                            component.elements = component.elements.filter(
                              (p, i) => i !== elementIndex
                            );
                            setPage({ ...page });
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "white",
                              color: "red",
                              borderRadius: 7,
                              display: "flex",
                              position: "absolute",
                              top: 0,
                              right: 0,
                            }}
                          >
                            <CloseCircleFilled
                              title={"Remove"}
                              style={{
                                color: "red",
                              }}
                            />
                          </div>
                        </Popconfirm>
                      )}
                  </div>
                );
              })}

            <Button
              icon={<PlusOutlined />}
              shape="circle"
              type={"primary"}
              style={{
                position: "absolute",
                bottom: "16px",
                right: "16px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                component.elements.push({
                  img: DEFAULT_IMG,
                  title: "Info Card Title",
                  description: "Info Card Description",
                  size: "md",
                  background: "light",
                  flexDirection: "row",
                });
                setPage({ ...page });
                setActiveElementIndex(component.elements.length - 1);
              }}
            />
          </Flex>
        );

      case ComponentType.FAQ:
        return (
          <Tabs
            tabBarExtraContent={{
              right: (
                <Space>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      setActiveKey(String(component.elements.length));
                      component.elements.push({
                        title: "Faq Group " + (component.elements.length + 1),
                        elements: [
                          {
                            q:
                              "Sample question for group " +
                              (component.elements.length + 1) +
                              "?",
                            a: "We have the answer for you.",
                          },
                        ],
                      });
                      setPage({
                        ...page,
                        components: [...page.components],
                      });
                    }}
                  >
                    Add Group
                  </Button>
                </Space>
              ),
            }}
            style={{
              backgroundColor: "#ccc",
              padding: 10,
              borderRadius: 20,
            }}
            onChange={setActiveKey}
            activeKey={activeKey}
            items={component.elements.map((group, groupIndex) => {
              return {
                label: (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column-reverse",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: 300,
                    }}
                    onClick={() => {
                      setActiveComponentIndex(componentIndex);
                      setActiveElementIndex(groupIndex);
                      setActiveItemIndex(NO_INDEX);
                    }}
                  >
                    <div
                      style={{
                        pointerEvents:
                          activeComponentIndex === componentIndex &&
                          activeElementIndex === groupIndex
                            ? "unset"
                            : "none",
                      }}
                    >
                      <Popover
                        trigger={"click"}
                        content={
                          <Input
                            style={{
                              minWidth: 300,
                              display: "block",
                            }}
                            value={group.title}
                            onChange={(e) => {
                              group.title = e.target.value;
                              setPage({ ...page });
                            }}
                          />
                        }
                      >
                        <Typography.Text strong>{group.title}</Typography.Text>
                      </Popover>
                    </div>

                    {activeComponentIndex === componentIndex &&
                      groupIndex === Number(activeKey) && (
                        <Space>
                          {groupIndex > 0 && (
                            <div
                              style={{
                                display: "flex",
                                backgroundColor: "white",
                                color: "blue",
                                borderRadius: 7,
                              }}
                            >
                              <Popconfirm
                                title="Remove group and all its questions"
                                onConfirm={() => {
                                  setActiveKey("0");
                                  component.elements =
                                    component.elements.filter(
                                      (g, index) => index !== groupIndex
                                    );
                                  setPage({
                                    ...page,
                                    components: [...page.components],
                                  });
                                }}
                              >
                                <LeftCircleFilled />
                              </Popconfirm>
                            </div>
                          )}

                          <div
                            style={{
                              backgroundColor: "white",
                              display: "flex",
                              borderRadius: 7,
                            }}
                          >
                            <Popconfirm
                              title="Remove group and all its questions"
                              onConfirm={() => {
                                setActiveKey("0");
                                component.elements = component.elements.filter(
                                  (g, index) => index !== groupIndex
                                );
                                setPage({
                                  ...page,
                                  components: [...page.components],
                                });
                              }}
                            >
                              <CloseCircleFilled style={{ color: "red" }} />
                            </Popconfirm>
                          </div>

                          {groupIndex < component.elements.length - 1 && (
                            <div
                              style={{
                                display: "flex",
                                backgroundColor: "white",
                                color: "blue",
                                borderRadius: 7,
                              }}
                            >
                              <Popconfirm
                                title="Remove group and all its questions"
                                onConfirm={() => {
                                  setActiveKey("0");
                                  component.elements =
                                    component.elements.filter(
                                      (g, index) => index !== groupIndex
                                    );
                                  setPage({
                                    ...page,
                                    components: [...page.components],
                                  });
                                }}
                              >
                                <RightCircleFilled />
                              </Popconfirm>
                            </div>
                          )}
                        </Space>
                      )}
                  </div>
                ),

                key: String(groupIndex),
                closable: false,
                children: (
                  <Flex wrap="wrap" gap={GAP}>
                    {group.elements?.map((faq, faqIndex) => (
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          width: "400px",
                          backgroundColor: "#eee",
                          borderRadius: 20,
                          padding: 10,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveItemIndex(faqIndex);
                          setActiveElementIndex(groupIndex);
                          setActiveComponentIndex(componentIndex);
                        }}
                      >
                        <div
                          style={{
                            pointerEvents:
                              activeComponentIndex === componentIndex &&
                              activeElementIndex === groupIndex &&
                              activeItemIndex === faqIndex
                                ? "unset"
                                : "none",
                          }}
                        >
                          <Popover
                            trigger={"click"}
                            content={
                              <input
                                style={{
                                  minWidth: 400,
                                  display: "block",
                                }}
                                value={faq.q}
                                onChange={(e) => {
                                  faq.q = e.target.value;
                                  setPage({ ...page });
                                }}
                              />
                            }
                          >
                            <Typography.Text
                              style={{ cursor: "pointer" }}
                              strong
                            >
                              {faq.q}
                            </Typography.Text>
                          </Popover>

                          <Popover
                            trigger={"click"}
                            content={
                              <textarea
                                style={{
                                  display: "block",
                                  minWidth: 400,
                                  minHeight: 160,
                                }}
                                value={faq.a}
                                onChange={(e) => {
                                  faq.a = e.target.value;
                                  setPage({ ...page });
                                }}
                              />
                            }
                          >
                            <Typography.Paragraph style={{ cursor: "pointer" }}>
                              {faq.a}
                            </Typography.Paragraph>
                          </Popover>
                        </div>

                        {activeComponentIndex === componentIndex &&
                          activeElementIndex === groupIndex &&
                          activeItemIndex === faqIndex &&
                          faqIndex > 0 && (
                            <div
                              style={{
                                backgroundColor: "white",
                                color: "#4096ff",
                                borderRadius: 7,
                                display: "flex",
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                              }}
                            >
                              <LeftCircleFilled
                                title={"Move Left"}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let currentInfo = group.elements[faqIndex];
                                  group.elements[faqIndex] =
                                    group.elements[faqIndex - 1];
                                  group.elements[faqIndex - 1] = currentInfo;
                                  setActiveElementIndex(faqIndex - 1);
                                  setPage({ ...page });
                                }}
                              />
                            </div>
                          )}

                        {activeComponentIndex === componentIndex &&
                          activeElementIndex === groupIndex &&
                          activeItemIndex === faqIndex &&
                          faqIndex < group.elements.length - 1 && (
                            <div
                              style={{
                                backgroundColor: "white",
                                color: "#4096ff",
                                borderRadius: 7,
                                display: "flex",
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                              }}
                            >
                              <RightCircleFilled
                                title={"Move Right"}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let currentInfo = group.elements[faqIndex];
                                  group.elements[faqIndex] =
                                    group.elements[faqIndex + 1];
                                  group.elements[faqIndex + 1] = currentInfo;
                                  setActiveElementIndex(faqIndex + 1);
                                  setPage({ ...page });
                                }}
                              />
                            </div>
                          )}

                        {activeComponentIndex === componentIndex &&
                          activeElementIndex === groupIndex &&
                          activeItemIndex === faqIndex && (
                            <div
                              style={{
                                display: "flex",
                                backgroundColor: "white",
                                color: "red",
                                position: "absolute",
                                top: 0,
                                right: 0,
                                borderRadius: 7,
                              }}
                            >
                              <CloseCircleFilled
                                title={"Remove"}
                                onClick={() => {
                                  group.elements = group.elements.filter(
                                    (p, i) => i !== faqIndex
                                  );
                                  setPage({ ...page });
                                }}
                              />
                            </div>
                          )}
                      </div>
                    ))}
                    <Button
                      shape="circle"
                      style={{
                        position: "absolute",
                        bottom: GAP / 2,
                        right: GAP / 2,
                      }}
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        component.elements[Number(activeKey)].elements.push({
                          q: "Question",
                          a: "Answer",
                        });

                        setPage({ ...page });
                      }}
                    />
                  </Flex>
                ),
              };
            })}
          />
        );

      case ComponentType.SPACE:
        return (
          <div className={`space ${component.size}`}>
            <Space
              style={{
                position: "absolute",
                left: GAP / 2,
                top: GAP / 2,
              }}
            >
              <Radio.Group
                value={component.size}
                size="small"
                buttonStyle="solid"
                onChange={(e) => {
                  console.log("onChange", e);
                  component.size = e.target.value;
                  setPage({ ...page });
                }}
              >
                <Radio.Button value="sm">S</Radio.Button>
                <Radio.Button value="md">M</Radio.Button>
                <Radio.Button value="lg">L</Radio.Button>
                <Radio.Button value="xl">X</Radio.Button>
              </Radio.Group>
            </Space>
          </div>
        );
    }
  };

  return (
    <div
      onClick={() => {
        setActiveComponentIndex(NO_INDEX);
        setActiveElementIndex(NO_INDEX);
      }}
      style={{
        display: "block",
      }}
    >
      {page.components.map((component, componentIndex) => {
        return (
          <div
            key={page.name + componentIndex}
            style={{ position: "relative" }}
          >
            {addSectionButton(componentIndex)}
            <div
              className="section"
              style={{
                borderColor:
                  activeComponentIndex === componentIndex &&
                  activeElementIndex === NO_INDEX
                    ? "#1677ff"
                    : "#ccc",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setActiveComponentIndex(componentIndex);
                setActiveElementIndex(NO_INDEX);
              }}
            >
              <div>{componentHTML(component, componentIndex)}</div>
              {activeComponentIndex === componentIndex &&
                activeElementIndex === NO_INDEX &&
                activeComponentIndex > 0 && (
                  <div
                    style={{
                      backgroundColor: "white",
                      color: "#4096ff",
                      borderRadius: 7,
                      display: "flex",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                  >
                    <UpCircleFilled
                      title={"Move Up"}
                      onClick={(e) => {
                        e.stopPropagation();
                        let currentComponent = page.components[componentIndex];
                        page.components[componentIndex] =
                          page.components[componentIndex - 1];
                        page.components[componentIndex - 1] = currentComponent;
                        setActiveComponentIndex(componentIndex - 1);
                        setPage({ ...page });
                      }}
                    />
                  </div>
                )}

              {activeComponentIndex === componentIndex &&
                activeElementIndex === NO_INDEX &&
                activeComponentIndex < page.components.length - 1 && (
                  <div
                    style={{
                      backgroundColor: "white",
                      color: "#4096ff",
                      borderRadius: 7,
                      display: "flex",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                    }}
                  >
                    <DownCircleFilled
                      title={"Move Down"}
                      onClick={(e) => {
                        e.stopPropagation();
                        let currentComponent = page.components[componentIndex];
                        page.components[componentIndex] =
                          page.components[componentIndex + 1];
                        page.components[componentIndex + 1] = currentComponent;
                        setActiveComponentIndex(componentIndex + 1);
                        setPage({ ...page });
                      }}
                    />
                  </div>
                )}

              {activeComponentIndex === componentIndex &&
                activeElementIndex === NO_INDEX && (
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "white",
                      borderRadius: 7,
                      color: "red",
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                  >
                    <CloseCircleFilled
                      onClick={() => {
                        setPage({
                          ...page,
                          components: page.components.filter(
                            (component, i) => i !== componentIndex
                          ),
                        });
                      }}
                      title="Remove"
                    />
                  </div>
                )}
            </div>
          </div>
        );
      })}

      {addSectionButton(page.components.length)}

      <div style={{ height: 200 }} />

      {componentIndex !== NO_INDEX && inputType !== InputType.Image && (
        <Modal
          open={true}
          closable={false}
          footer={null}
          onCancel={cancelModal}
          width={"80vw"}
          onOk={() => {}}
        >
          {inputType === InputType.TextArea && (
            <Space direction={"vertical"}>
              <Input.TextArea
                value={componentValue}
                onChange={(e) => setComponentValue(e.target.value)}
              />
              <Button
                type={"primary"}
                onClick={() => handleInput(componentValue)}
              >
                OK
              </Button>
            </Space>
          )}

          {inputType === InputType.Text && (
            <Space direction={"vertical"}>
              <Input
                value={componentValue}
                onChange={(e) => setComponentValue(e.target.value)}
              />
              <Button
                type={"primary"}
                onClick={() => handleInput(componentValue)}
              >
                OK
              </Button>
            </Space>
          )}
          {inputType === InputType.Product && (
            <Flex wrap="wrap">
              <Space direction="vertical">
                <Typography.Text strong>Tours</Typography.Text>
                <Tours
                  tours={tours}
                  isEditor={false}
                  select={(product) => {
                    page.components[componentIndex][componentKey].push(
                      product.sId
                    );
                    setPage({ ...page });

                    cancelModal();
                  }}
                  create={create}
                  update={update}
                  upload={upload}
                  remove={remove}
                  getImgUrl={getImgUrl}
                />
              </Space>

              <Divider />
              <Space direction="vertical">
                <Typography.Text strong>Audibles</Typography.Text>
                <Audibles
                  audibles={audibles}
                  isEditor={false}
                  select={(product) => {
                    page.components[componentIndex][componentKey].push(
                      product.sId
                    );
                    setPage({ ...page });
                    cancelModal();
                  }}
                  upload={upload}
                  create={create}
                  remove={remove}
                  update={update}
                  getImgUrl={getImgUrl}
                  getAudioUrl={getAudioUrl}
                />
              </Space>
            </Flex>
          )}

          {inputType === InputType.Link && (
            <Col>
              <Space>
                <Col>
                  <Typography.Text strong>Tours</Typography.Text>
                  {tours.map((tour) => (
                    <Row>
                      <Typography.Link onClick={() => handleInput(tour.sId)}>
                        {tour.name}
                      </Typography.Link>
                    </Row>
                  ))}
                </Col>
                <Col>
                  <Typography.Text strong>Audibles</Typography.Text>
                  {audibles.map((audible) => (
                    <Row>
                      <Typography.Link onClick={() => handleInput(audible.sId)}>
                        {audible.name}
                      </Typography.Link>
                    </Row>
                  ))}
                </Col>
                <Col>
                  <Typography.Text strong>Blogs</Typography.Text>
                  {blogs.map((page) => (
                    <Row>
                      <Typography.Link onClick={() => handleInput(page.sId)}>
                        {page.name}
                      </Typography.Link>
                    </Row>
                  ))}
                </Col>
                <Col>
                  <Typography.Text strong>Pages</Typography.Text>
                  {pages.map((page) => (
                    <Row>
                      <Typography.Link onClick={() => handleInput(page.sId)}>
                        {page.name}
                      </Typography.Link>
                    </Row>
                  ))}
                </Col>
              </Space>
              <Row justify={"end"}>
                <Col>
                  <Typography.Link
                    onClick={() => handleInput(undefined)}
                    style={{ color: "red" }}
                  >
                    Delete
                  </Typography.Link>
                </Col>
              </Row>
            </Col>
          )}
        </Modal>
      )}
    </div>
  );
}
