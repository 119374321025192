import { Button, DatePicker, Form, InputNumber, Select } from "antd";

export default function SlotCreator({ tours, onFinish }) {
  return (
    <Form labelCol={{ span: 8 }} onFinish={onFinish}>
      <Form.Item
        label={"Product"}
        name={"sId"}
        rules={[{ required: true, message: "Please select a Product" }]}
      >
        <Select>
          {tours.map((tour) => (
            <Select.Option value={tour.sId}>{tour.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={"Date/Time"}
        name={"date"}
        rules={[{ required: true, message: "Please select Date and Time" }]}
      >
        <DatePicker
          format={"DD MMM YYYY - HH:mm"}
          showTime={true}
          showSecond={false}
          showNow={false}
          minuteStep={15}
        />
      </Form.Item>
      <Form.Item
        label={"Availability"}
        name={"pax"}
        rules={[{ required: true, message: "Please insert the availability" }]}
      >
        <InputNumber min={1} max={100} />
      </Form.Item>
      <Form.Item
        label={"Price"}
        name={"price"}
        rules={[{ required: true, message: "Please insert the price" }]}
      >
        <InputNumber addonAfter={"€"} min={1} max={1000} />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
