export const NO_INDEX = -1;
export const DEFAULT_IMG = "00000000-0000-0000-0000-000000000000";
export const SQUARE_LOGO = "00000000-0000-0000-0000-000000000001";
export const GHOST_LOGO = "00000000-0000-0000-0000-000000000002";
export const COMPLETE_LOGO = "00000000-0000-0000-0000-000000000003";
export const MAP_PIN = "00000000-0000-0000-0000-000000000004";
export const getUuid = () => {
  let d = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
};

export const timestampToFloat = (timeString) => {
  var parts = timeString.split(":");
  var hours = parseInt(parts[0], 10);
  var minutes = parseInt(parts[1], 10);
  var secondsAndMilliseconds = parseFloat(parts[2]);
  return hours * 3600 + minutes * 60 + secondsAndMilliseconds;
};

export const formatTimestamp = function (duration: number) {
  // Separate into minutes and fractional part (seconds and milliseconds)
  var hours = Math.floor(duration / 3600);
  var minutes = Math.floor((duration % 3600) / 60);
  var seconds = Math.floor(duration % 60);
  var milliseconds = Math.floor((duration % 1) * 1000);

  // Format minutes, seconds, and milliseconds
  return (
    (hours < 10 ? "0" : "") +
    hours +
    ":" +
    (minutes < 10 ? "0" : "") +
    minutes +
    ":" +
    (seconds < 10 ? "0" : "") +
    seconds +
    "." +
    (milliseconds < 10 ? "00" : milliseconds < 100 ? "0" : "") +
    milliseconds
  );
};

export const toUrlPath = function (title) {
  return title
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "")
    .split("-")
    .filter(
      (token) =>
        token !== "a" &&
        token !== "an" &&
        token !== "in" &&
        token !== "on" &&
        token !== "to" &&
        token !== "does" &&
        token !== "has" &&
        token !== "into" &&
        token !== "the"
    )
    .join("-");
};
