import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { Space, Spin, Typography } from "antd";
import { STRIPE } from ".";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { environment } from "./environments/environment";
import { unset } from "lodash";

export const Subscribe = ({ apiKey }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [clientSecret, setClientSecret] = useState<string>();

  //   useEffect(() => {
  //     setTimeout(() => setClientSecret(SECRET), 5000);
  //   }, []);

  useEffect(() => {
    console.log("useEffect[state]");
    fetch(`${environment.baseUrl}/api/subscribe`, {
      headers: {
        "x-api-key": apiKey,
        "lookup-key": state.lookupKey,
      },
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret))
      .catch(() => {
        navigate("/");
      });
  }, [state]);

  return (
    <Spin spinning={!clientSecret}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          padding: "1rem",
          overflow: "scroll",
          alignItems: "stretch",
          justifyContent: "center",
        }}
      >
        {clientSecret && (
          <EmbeddedCheckoutProvider stripe={STRIPE} options={{ clientSecret }}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </Spin>
  );
};
