import React, { useState } from "react";
import "./FAQ.css";

interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    question: "What is Eplostar?",
    answer:
      "Eplostar is a platform for creating extremely fast websites that achieve perfect scores on PageSpeed Insights. Eplostar can help you launch your guided tour operations with our seamless booking system and website builder.",
  },
  {
    question: "Should'I care about PageSpeed scores?",
    answer:
      "Page speed is a ranking factor in Google’s algorithm. Websites with better PageSpeed Insights scores rank higher in search results. It directly impacts various aspects of your website's performance, user experience, and ultimately, your business success.",
  },
  {
    question: "Why do I need a Mobile Website?",
    answer:
      "Google primarily uses the mobile version of a site for indexing and ranking. A high PageSpeed Insights score on mobile ensures your site performs well for mobile users, who make up a significant portion of internet traffic.",
  },
  {
    question: "How do you handle Payments?",
    answer:
      "We use Stripe, a trusted and secure payment processor, to handle all transactions. Stripe ensures your payment information is processed safely and securely.",
  },
];

const FAQ: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      {faqData.map((item, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            {item.question}
          </div>
          <div
            className={`faq-answer ${activeIndex === index ? "active" : ""}`}
          >
            {item.answer}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
