import {
  CheckCircleOutlined,
  CreditCardOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Flex, Popover, Row, Space, Typography } from "antd";

const CheckoutSession = ({
  checkoutSession,
  isOpen,
  markAsCompleted,
  expireSession,
}) => {
  console.log(checkoutSession);
  return (
    <Popover
      key={checkoutSession.sId}
      placement={"bottom"}
      open={isOpen}
      content={
        <Flex vertical>
          <Space>
            <CreditCardOutlined />
            <Typography.Text>{checkoutSession.name}</Typography.Text>
          </Space>
          <Space>
            <MailOutlined />
            <Typography.Text>{checkoutSession.email}</Typography.Text>
          </Space>
          <Space>
            <UserOutlined />
            <Typography.Text>{checkoutSession.seats}</Typography.Text>
          </Space>
          <Space>
            <CheckCircleOutlined />
            <Typography.Text>€{checkoutSession.price / 100}</Typography.Text>
          </Space>
        </Flex>
      }
    >
      <Row
        justify={"center"}
        style={{
          backgroundColor:
            checkoutSession.paymentStatus === "paid"
              ? checkoutSession.workStatus === "completed"
                ? "#cccccc"
                : "#caadf4"
              : "#000000",
          borderRadius: 4,
          marginTop: 2,
          marginLeft: 1,
          marginRight: 1,
        }}
      >
        <Typography.Text
          style={{
            color: "white",
          }}
        >
          {checkoutSession.seats}
        </Typography.Text>
      </Row>
    </Popover>
  );
};

export default CheckoutSession;
