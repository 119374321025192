import { PlusSquareFilled } from "@ant-design/icons";

export const FileInputButton = ({ upload, accept, title, id }) => {
  return (
    <label
      style={{
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      htmlFor={id}
      title={title}
    >
      <PlusSquareFilled />
      <input
        onChange={(event) => {
          if (event.target.files[0]) {
            upload(event.target.files[0]);
          }
        }}
        style={{ display: "none" }}
        type="file"
        accept={accept}
        id={id}
      />
    </label>
  );
};
