export const environment = {
  name: "eplostar-prod",
  email: "ariannace@gmail.com",
  phone: "+39 340 504 1973",
  userPoolId: "us-east-1_6VDifDym9",
  userPoolWebClientId: "7t3dnu8ffcgpen18h8962jtlis",
  stripePublishableKey: "pk_live_51LzFC1BoYNzBgZzRm4cnXXZMyXNGJ9JfgO8yrbWTXgWifw9QZrnvRKqtkg5x9AF8x9Ald2fk9n2SAlKBEzyQGjYE00CBzvmMbc",
  region: "us-east-1",
  webSocketApiId: "eysz7kcqt8",
  apiKey: "cbc89713-6d86-12cb-a18b-37f9d33c0def",
  baseUrl: "https://www.eplostar.com",
  baseUrlImager: "https://img.eplostar.com"
};
