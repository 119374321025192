import {
  Button,
  Col,
  Flex,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Slider,
  Spin,
  Tag,
  Typography,
} from "antd";
import {
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  MinusCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { NO_INDEX, formatTimestamp, timestampToFloat } from "./Utils";
import { useState } from "react";
import { Slide, Tag as TagList } from "./domain/domain";
import ImageInput from "./ImageInput";
import { GAP } from "./PageEditor";
import { FileInputButton } from "./FileInputButton";

export const PREFIX_AUDIOS = "audios/";
export const PREFIX_VTT = "vtt/";
export default function AudibleEditor({
  audible,
  upload,
  getImgUrl,
  getAudioUrl,
  update,
  onClose,
}) {
  const [audibleSpinning, setAudibleSpinning] = useState(false);
  const [chapterEditIndex, setChapterEditIndex] = useState(NO_INDEX);
  const [activeMarkIndex, setActiveMarkIndex] = useState(NO_INDEX);
  const [positionEditIndex, setPositionEditIndex] = useState(NO_INDEX);
  const [activeSlide, setActiveSlide] = useState<Slide>();
  const [mp3Duration, setMp3Duration] = useState(0);

  const removeVtt = () => {
    delete audible.vtt;
    update({ ...audible });
  };

  const removeAudio = () => {
    delete audible.audio;
    update({ ...audible });
  };

  const processMetadata = (metadata: any) => {
    setMp3Duration(Math.round(metadata.target.duration));
  };

  const insertChapter = () => {
    audible.chapters.push({
      name: "Chapter " + audible.chapters.length,
      position:
        audible.chapters.length > 0
          ? audible.chapters[audible.chapters.length - 1].position + 1
          : 0,
      top: 40,
      left: 40,
      pole: "left",
    });
    setActiveMarkIndex(audible.chapters.length - 1);
    update(audible);
  };

  console.log("audible", audible);
  return (
    <>
      <Modal
        title={"Audible Editor"}
        open={true}
        footer={null}
        closable
        width={1000}
        onCancel={onClose}
      >
        <Spin spinning={audibleSpinning}>
          <Row align={"middle"}>
            <Col span={4}>
              <Row justify={"end"}>
                <Typography.Text>Legend</Typography.Text>
                <Typography.Text
                  style={{ marginInlineStart: 2, marginInlineEnd: 8 }}
                >
                  :
                </Typography.Text>
              </Row>
            </Col>
            <Col span={18}>
              <Row justify={"start"} align={"middle"}>
                <div style={{ position: "relative" }}>
                  <ImageInput
                    imageUrl={getImgUrl(audible.legend)}
                    id={"audible_legend"}
                    upload={(file, path) => {
                      console.log("upload", file.type, path);
                      return upload(file, path).then((key) => {
                        audible.legend = key;
                        update({ ...audible });
                      });
                    }}
                    onRemove={undefined}
                  />
                  <div
                    id="markers"
                    onClick={(e) => {
                      if (activeMarkIndex !== NO_INDEX) {
                        setActiveMarkIndex(NO_INDEX);
                        e.stopPropagation();
                      } else {
                        return false;
                      }
                    }}
                  >
                    {audible.chapters.map((chapter, index) => (
                      <div
                        className={`marker ${chapter.pole}${
                          activeMarkIndex === index ? " active" : ""
                        }`}
                        style={{
                          top: chapter.top + "%",
                          left: chapter.left + "%",
                        }}
                      >
                        <p
                          onClick={() => {
                            if (activeMarkIndex !== index) {
                              setActiveMarkIndex(index);
                            } else {
                              setPositionEditIndex(index);
                            }
                          }}
                        >
                          {chapter.name}
                        </p>

                        {activeMarkIndex === index && (
                          <MinusCircleFilled
                            style={{
                              color: "red",
                              position: "absolute",
                              top: 2,
                              right: 2,
                            }}
                            onClick={() => {
                              audible.chapters = audible.chapters.filter(
                                (_, i) => i !== index
                              );
                              update(audible);
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </div>

                  <Button
                    size="small"
                    icon={<PlusOutlined />}
                    style={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      boxShadow: "0 0 4px 4px white",
                    }}
                    type="primary"
                    onClick={insertChapter}
                  />
                </div>

                <Flex
                  vertical
                  align="center"
                  style={{ margin: 10, flexShrink: 0 }}
                >
                  <div>
                    <Button
                      disabled={
                        activeMarkIndex === NO_INDEX ||
                        audible.chapters[activeMarkIndex].top === 0
                      }
                      size="small"
                      type="primary"
                      icon={<ArrowUpOutlined />}
                      onClick={() => {
                        audible.chapters[activeMarkIndex].top =
                          audible.chapters[activeMarkIndex].top - 1;
                        update({ ...audible });
                      }}
                    />
                  </div>
                  <Flex align="center">
                    <Button
                      disabled={
                        activeMarkIndex === NO_INDEX ||
                        audible.chapters[activeMarkIndex].left === 0
                      }
                      size="small"
                      type="primary"
                      icon={<ArrowLeftOutlined />}
                      onClick={() => {
                        audible.chapters[activeMarkIndex].left =
                          audible.chapters[activeMarkIndex].left - 1;
                        update({ ...audible });
                      }}
                    />
                    <div
                      style={{
                        width: 30,
                        height: 30,
                        display: "inline-block",
                      }}
                    ></div>
                    <Button
                      disabled={
                        activeMarkIndex === NO_INDEX ||
                        audible.chapters[activeMarkIndex].left === 100
                      }
                      size="small"
                      type="primary"
                      icon={<ArrowRightOutlined />}
                      onClick={() => {
                        audible.chapters[activeMarkIndex].left =
                          audible.chapters[activeMarkIndex].left + 1;
                        update({ ...audible });
                      }}
                    />
                  </Flex>
                  <div>
                    <Button
                      disabled={
                        activeMarkIndex === NO_INDEX ||
                        audible.chapters[activeMarkIndex].top === 100
                      }
                      size="small"
                      type="primary"
                      icon={<ArrowDownOutlined />}
                      onClick={() => {
                        audible.chapters[activeMarkIndex].top =
                          audible.chapters[activeMarkIndex].top + 1;
                        update({ ...audible });
                      }}
                    />
                  </div>

                  <Radio.Group
                    value={audible.chapters[activeMarkIndex]?.pole}
                    style={{ marginTop: GAP }}
                    disabled={activeMarkIndex === NO_INDEX}
                    onChange={(e) => {
                      audible.chapters[activeMarkIndex].pole = e.target.value;
                      update(audible);
                    }}
                    buttonStyle="solid"
                    size="small"
                  >
                    <Radio.Button value="right">↰</Radio.Button>
                    <Radio.Button value="left">↱</Radio.Button>
                  </Radio.Group>
                </Flex>
              </Row>
            </Col>
          </Row>

          <Row align={"middle"} style={{ marginTop: 24 }}>
            <Col span={4}>
              <Row justify={"end"}>
                <Typography.Text>Mp3</Typography.Text>
                <Typography.Text
                  style={{ marginInlineStart: 2, marginInlineEnd: 8 }}
                >
                  :
                </Typography.Text>
              </Row>
            </Col>
            <Col span={18}>
              <Row align={"middle"}>
                <Col span={24}>
                  {audible.audio ? (
                    <>
                      <audio
                        style={{ width: "100%" }}
                        controls
                        onLoadedMetadata={processMetadata}
                      >
                        <source src={getAudioUrl(audible.audio)}></source>
                      </audio>
                      <MinusCircleFilled
                        style={{
                          color: "red",
                          margin: 10,
                          position: "absolute",
                          right: -26,
                          top: -10,
                        }}
                        title="Remove"
                        onClick={removeAudio}
                      />
                    </>
                  ) : (
                    <>
                      <label
                        style={{ cursor: "pointer" }}
                        htmlFor="audio_input"
                        title="Upload a mp3"
                      >
                        <Tag color="blue">{audible.audio || "Upload"}</Tag>
                      </label>
                      <input
                        onChange={(data) => {
                          if (data.target.files[0]) {
                            setAudibleSpinning(true);
                            upload(data.target.files[0], PREFIX_AUDIOS)
                              .then((key) => {
                                audible.audio = key;
                                update(audible);
                              })
                              .finally(() => setAudibleSpinning(false));
                          }
                        }}
                        style={{ display: "none" }}
                        type="file"
                        accept="audio/mp3"
                        id="audio_input"
                      />
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row align={"middle"}>
            <Col span={4}>
              <Row justify={"end"}>
                <Typography.Text>WebVTT</Typography.Text>
                <Typography.Text
                  style={{ marginInlineStart: 2, marginInlineEnd: 8 }}
                >
                  :
                </Typography.Text>
              </Row>
            </Col>
            <Col span={18} style={{ marginTop: 24, marginBottom: 24 }}>
              {audible.vtt ? (
                <Row style={{ position: "relative" }}>
                  <Col span={24}>
                    <Input value={audible.vtt} disabled />
                  </Col>
                  <MinusCircleFilled
                    style={{
                      color: "red",
                      margin: 10,
                      position: "absolute",
                      right: -26,
                      top: -10,
                    }}
                    title="Remove"
                    onClick={removeVtt}
                  />
                </Row>
              ) : (
                <>
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor="vtt_input"
                    title="Upload a WebVTT"
                  >
                    <Tag color="blue">{audible.vtt || "Upload"}</Tag>
                  </label>
                  <input
                    onChange={(data) => {
                      if (data.target.files[0]) {
                        setAudibleSpinning(true);
                        upload(data.target.files[0], PREFIX_VTT)
                          .then((key) => {
                            audible.vtt = key;
                            update(audible);
                          })
                          .finally(() => setAudibleSpinning(false));
                      }
                    }}
                    style={{ display: "none" }}
                    type="file"
                    accept="text/vtt"
                    id="vtt_input"
                  />
                </>
              )}
            </Col>
          </Row>

          <Row align={"middle"} style={{ marginTop: 14 }}>
            <Col span={4}>
              <Row justify={"end"}>
                <Typography.Text>Name</Typography.Text>
                <Typography.Text
                  style={{ marginInlineStart: 2, marginInlineEnd: 8 }}
                >
                  :
                </Typography.Text>
              </Row>
            </Col>
            <Col span={18}>
              <Input
                placeholder="Audible Name"
                value={audible.name}
                onChange={(event) => {
                  audible.name = event.target.value;
                  update(audible);
                }}
              />
            </Col>
          </Row>

          <Row align={"top"} style={{ marginTop: 24 }}>
            <Col span={4}>
              <Row justify={"end"}>
                <Typography.Text>Tag</Typography.Text>
                <Typography.Text
                  style={{ marginInlineStart: 2, marginInlineEnd: 8 }}
                >
                  :
                </Typography.Text>
              </Row>
            </Col>
            <Col span={19}>
              <Select
                size="small"
                value={audible?.tag}
                style={{ width: 200 }}
                placeholder={"Select a Tag"}
                options={Object.values(TagList).map((tag) => {
                  return {
                    label: tag.toUpperCase().replaceAll("-", " "),
                    value: tag,
                  };
                })}
                onChange={(value) => update({ ...audible, tag: value })}
              />
            </Col>
          </Row>

          <Row align={"middle"} style={{ marginTop: 24 }}>
            <Col span={4}>
              <Row justify={"end"}>
                <Typography.Text>Slides</Typography.Text>
                <Typography.Text
                  style={{ marginInlineStart: 2, marginInlineEnd: 8 }}
                >
                  :
                </Typography.Text>
              </Row>
            </Col>
            <Col span={18}>
              <Flex wrap="wrap" gap={16} align="center">
                {audible.slides.map((slide, index) => (
                  <div style={{ position: "relative" }}>
                    <ImageInput
                      id={"images_" + index}
                      imageUrl={getImgUrl(
                        slide.img,
                        window.devicePixelRatio * 160,
                        window.devicePixelRatio * 120
                      )}
                      upload={(file, path) =>
                        upload(file, path).then((key) => {
                          slide.img = key;
                          update(audible);
                        })
                      }
                      onRemove={() => {
                        audible.slides = audible.slides.filter(
                          (_, i) => i !== index
                        );
                        update(audible);
                      }}
                    />

                    <Tag
                      style={{
                        fontFamily: "monospace",
                        position: "absolute",
                        top: 8,
                        left: 8,
                        cursor: "pointer",
                      }}
                      onClick={() => setActiveSlide(slide)}
                    >
                      {formatTimestamp(audible.slides[index].position)}
                    </Tag>
                  </div>
                ))}

                <FileInputButton
                  title={"Add Slide"}
                  id="new_slide"
                  accept={"image/png, image/jpeg, image/svg+xml, image/webp"}
                  upload={(file) => {
                    upload(file, "images/").then((key) => {
                      audible.slides.push({
                        img: key,
                        position:
                          audible.slides.length > 0
                            ? audible.slides[audible.slides.length - 1]
                                .position + 1
                            : 0,
                      });
                      update(audible);
                    });
                  }}
                />
              </Flex>
            </Col>
          </Row>
        </Spin>
      </Modal>

      <Modal
        title={"Position"}
        open={positionEditIndex !== NO_INDEX || !!activeSlide}
        onCancel={() => {
          setPositionEditIndex(NO_INDEX);
          setActiveSlide(undefined);
        }}
        closeIcon={null}
        footer={false}
      >
        <Flex vertical>
          <Flex align="center">
            <input
              type="time"
              step="0.001"
              value={formatTimestamp(
                positionEditIndex !== NO_INDEX
                  ? audible.chapters[positionEditIndex].position
                  : activeSlide
                  ? activeSlide.position
                  : 0
              )}
              onChange={(event) => {
                console.log(
                  "onChange",
                  event.target.value,
                  timestampToFloat(event.target.value)
                );
                if (positionEditIndex !== NO_INDEX) {
                  audible.chapters[positionEditIndex].position =
                    timestampToFloat(event.target.value);
                  audible.chapters.sort((a, b) =>
                    a.position >= b.position ? 1 : -1
                  );
                } else {
                  audible.slides.find(
                    (slide) => slide.img === activeSlide.img
                  ).position = timestampToFloat(event.target.value);
                  audible.slides.sort((a, b) =>
                    a.position >= b.position ? 1 : -1
                  );
                }
                update({ ...audible });
              }}
            ></input>
          </Flex>

          {positionEditIndex !== NO_INDEX && (
            <Input
              value={audible.chapters[positionEditIndex].name}
              onChange={(e) => {
                audible.chapters[positionEditIndex].name = e.target.value;
                update({ ...audible });
              }}
            />
          )}
        </Flex>
      </Modal>
    </>
  );
}
