import { Card, Flex, Text, Button } from "@aws-amplify/ui-react";
import { Col, Row, Space } from "antd";
import imgWebsiteBuilder from "./assets/next.svg";
import imgCalendar from "./assets/calendar.svg";
import imgDesign from "./assets/design.svg";
import imgRocket from "./assets/rocketScores.svg";
import demoPreview from "./assets/preview_mac_2.png";
import Logo from "./assets/logo.svg";
import LogoInverted from "./assets/logo-inverted.svg";

import {
  CheckCircleFilled,
  CloseCircleOutlined,
  ControlOutlined,
  CrownOutlined,
  RocketOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { environment } from "./environments/environment";
import { AccentSlides } from "./AccentSlides";
import FAQ from "./FAQ";
import { PreviewWebsite } from "./PreviewWebsite";

export default function LandingPage() {
  const navigate = useNavigate();
  const cards = [
    {
      icon: <CrownOutlined />,
      title: "Signup to portal",
      content:
        "After choosing a subsciption plan, you'll be able to set your password and customize your account settings. Once your profile is complete, you can immediately start enjoying all the benefits and features of our platform.",
    },
    {
      icon: <ControlOutlined />,
      title: "Add products",
      content:
        "Start by logging into your dashboard with your administrator credentials. Once you're in, navigate to the 'Products' section, typically found in the main menu or sidebar. Click on the 'Add New Product' button to open the product entry form.",
    },
    {
      icon: <RocketOutlined />,
      title: "Publish and Enjoy",
      content:
        "First, ensure that all the content, images, and settings on your website are finalized and ready for public viewing. If everything looks good, proceed by clicking the 'Publish' button. This action will trigger the deployment process, which may take a few moments.",
    },
  ];
  return (
    <Col id="landing">
      <div id="hero">
        <img src={imgRocket} />
        <Button
          style={{
            cursor: "pointer",
            margin: 20,
            position: "absolute",
            top: 0,
            right: 0,
          }}
          onClick={() => navigate("admin")}
          variation="primary"
        >
          Sign In
        </Button>
      </div>

      <Flex id="preview_container" direction={"column"}>
        <h2>
          Cutting-Edge Booking System
          <br />
          Guaranteed Top SEO Rankings
        </h2>

        <img src={demoPreview} id="demo_preview" />
        <Button
          onClick={() => window.open("https://tours.eplostar.com", "_blank")}
          variation="primary"
          style={{
            backgroundColor: "white",
            color: "black",
          }}
        >
          Live Preview
        </Button>
        <p>
          Launch your guided tour operations with our seamless booking system
          and website builder. Our platforms generate blazing fast websites that
          achieve a perfect 100/100 on PageSpeed Insights, ensuring an
          exceptional user experience. Benefit from improved SEO optimization to
          boost your Google search rankings. Each site is hosted on our
          next-generation cloud infrastructure, capable of scaling to millions
          of clients effortlessly. Join us and elevate your online presence
          today!
        </p>
      </Flex>

      <div id="plans">
        <Card
          variation="elevated"
          style={{
            borderColor: "var(--amplify-colors-border-primary)",
            borderWidth: 1,
            margin: 20,
            width: 240,
          }}
        >
          <Flex direction="column" gap="xs">
            <Text textAlign={"center"} fontSize="x-large">
              Free
            </Text>
            <Row
              align={"bottom"}
              justify={"center"}
              style={{ marginBottom: 10 }}
            >
              <Text fontSize="xx-large" fontWeight="semibold">
                0€
              </Text>
              <Text fontSize={"small"} marginBottom={8} marginLeft={8}>
                per month
              </Text>
            </Row>

            <Col>
              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>4% transaction fee</Text>
                </Space>
              </Row>
              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>Single Page Website</Text>
                </Space>
              </Row>

              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>1 Tour Product</Text>
                </Space>
              </Row>

              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>1 Audible Guide</Text>
                </Space>
              </Row>

              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>1 Blog Post</Text>
                </Space>
              </Row>

              <Row justify={"start"} style={{ color: "#ccc" }}>
                <Space>
                  <CloseCircleOutlined />
                  <Text color={"#ccc"}>Advanced Components</Text>
                </Space>
              </Row>
              <Row justify={"start"} style={{ color: "#ccc" }}>
                <Space>
                  <CloseCircleOutlined />
                  <Text color={"#ccc"}>Custom Integrations</Text>
                </Space>
              </Row>
              <Row justify={"start"} style={{ color: "#ccc" }}>
                <Space>
                  <CloseCircleOutlined />
                  <Text color={"#ccc"}>Unlimited Support</Text>
                </Space>
              </Row>
            </Col>

            <Button
              onClick={() =>
                navigate("subscribe", {
                  state: { lookupKey: "eplostar_price_free" },
                })
              }
              variation="primary"
            >
              Subscribe
            </Button>
          </Flex>
        </Card>
        <Card
          variation="elevated"
          style={{
            borderColor: "var(--amplify-colors-border-primary)",
            borderWidth: 1,
            margin: 20,
            width: 240,
          }}
        >
          <Flex direction="column" gap="xs">
            <Text textAlign={"center"} fontSize="x-large">
              Basic
            </Text>
            <Row
              align={"bottom"}
              justify={"center"}
              style={{ marginBottom: 10 }}
            >
              <Text fontSize="xx-large" fontWeight="semibold">
                399€
              </Text>
              <Text fontSize={"small"} marginBottom={8} marginLeft={8}>
                per month
              </Text>
            </Row>
            <Col>
              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>2% transaction fee</Text>
                </Space>
              </Row>
              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>Multiple Page Website</Text>
                </Space>
              </Row>

              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>9 Tour Products</Text>
                </Space>
              </Row>

              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>9 Audible Guides</Text>
                </Space>
              </Row>

              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>9 Blog Post</Text>
                </Space>
              </Row>

              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>Advanced Components</Text>
                </Space>
              </Row>
              <Row justify={"start"} style={{ color: "#ccc" }}>
                <Space>
                  <CloseCircleOutlined />
                  <Text color={"#ccc"}>Custom Integrations</Text>
                </Space>
              </Row>
              <Row justify={"start"} style={{ color: "#ccc" }}>
                <Space>
                  <CloseCircleOutlined />
                  <Text color={"#ccc"}>Unlimited Support</Text>
                </Space>
              </Row>
            </Col>

            <Button
              onClick={() =>
                navigate("subscribe", {
                  state: { lookupKey: "eplostar_price_basic" },
                })
              }
              variation="primary"
            >
              Subscribe
            </Button>
          </Flex>
        </Card>
        <Card
          variation="elevated"
          style={{
            borderColor: "var(--amplify-colors-border-primary)",
            borderWidth: 1,
            margin: 20,
            width: 240,
          }}
        >
          <Flex direction="column" gap="xs">
            <Text textAlign={"center"} fontSize="x-large">
              Premium
            </Text>
            <Row
              align={"bottom"}
              justify={"center"}
              style={{ marginBottom: 10 }}
            >
              <Text fontSize="xx-large" fontWeight="semibold">
                999€
              </Text>
              <Text fontSize={"small"} marginBottom={8} marginLeft={8}>
                per month
              </Text>
            </Row>
            <Col>
              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>0% transaction fee</Text>
                </Space>
              </Row>
              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>Multiple Page Website</Text>
                </Space>
              </Row>

              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>99 Tour Products</Text>
                </Space>
              </Row>

              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>99 Audible Guides</Text>
                </Space>
              </Row>

              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>99 Blog Post</Text>
                </Space>
              </Row>

              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>Advanced Components</Text>
                </Space>
              </Row>
              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>Custom Integrations</Text>
                </Space>
              </Row>
              <Row justify={"start"}>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color:
                        "var(--amplify-components-button-primary-background-color)",
                    }}
                  />
                  <Text>Unlimited Support</Text>
                </Space>
              </Row>
            </Col>

            <Button
              onClick={() =>
                navigate("subscribe", {
                  state: { lookupKey: "eplostar_price_premium" },
                })
              }
              variation="primary"
            >
              Subscribe
            </Button>
          </Flex>
        </Card>
      </div>
      <p style={{ textAlign: "center", marginLeft: 20, marginRight: 20 }}>
        Choose the plan that better fits your needs.
        <br />
        You can switch or cancel the subscription at any moment.
      </p>

      <AccentSlides
        mainTitle="How it works?"
        subTitle="It is very easy and intuitive to use our portal"
        cards={cards}
      />

      <div className="infos">
        <img src={imgCalendar} />
        <div>
          <h1 className="title">Booking Experience</h1>
          <p>
            For travel enthusiasts and tour operators, We include a specialized
            platform to manage your tours effortlessly. Showcase your tours,
            handle bookings, and collect payments securely, all from one
            convenient dashboard.
          </p>
        </div>
      </div>

      <div className="infos">
        <img src={imgWebsiteBuilder} />
        <div>
          <h1 className="title">NextGen Solutions</h1>
          <p>
            Focus on your core bussines and let us do the heavy loads behind the
            scenes. Don't spend time making repeated operation by hand. Let us
            handle your daily operation in an automated fashion.
          </p>
        </div>
      </div>

      <div className="infos">
        <img src={imgDesign} />
        <div>
          <h1 className="title">High Performance</h1>
          <p>
            Use our web builder to create your own website. We will host it on
            our cutting edge cloud infrastructure that is capable of delivering
            unbelievable response times.
          </p>
        </div>
      </div>

      <div id="footer">
        <div>
          <img src={LogoInverted} />
          <p>{environment.name}</p>
          <p>{environment.email}</p>
          <p>{environment.phone}</p>
          <a href="https://storyset.com/work">Work illustrations by Storyset</a>
        </div>

        <FAQ />
      </div>
    </Col>
  );
}
