import { MinusCircleFilled } from "@ant-design/icons";
import { Row, Typography, Badge, Popconfirm } from "antd";

interface Props {
  name: string;
  desc?: string;
  img?: string;
  onClick: () => void;
  onDelete?: () => void;
}

export default function ProductCard(props: Props) {
  return (
    <Badge text={props.name}>
      <div
        title={props.onDelete ? "Open Editor" : "Select Card"}
        onClick={props.onClick}
        style={{
          position: "relative",
          width: 240,
          margin: 24,
          cursor: "pointer",
          borderRadius: 20,
          backgroundColor: "white",
          textAlign: "center",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <img
          src={props.img}
          width={240}
          height={160}
          style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}
        />
        <div style={{ paddingLeft: 6, paddingRight: 6, paddingBottom: 6 }}>
          <Row justify={"center"}>
            <Typography.Text strong>{props.name}</Typography.Text>
          </Row>

          <Typography.Text>{props.desc}</Typography.Text>
        </div>

        {props.onDelete && (
          <div onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              onConfirm={props.onDelete}
              title={
                "The tour will be also removed from all pages and section where it was inserted"
              }
            >
              <MinusCircleFilled
                style={{
                  color: "red",
                  position: "absolute",
                  top: -7,
                  right: -7,
                }}
                title="Delete"
              />
            </Popconfirm>
          </div>
        )}
      </div>
    </Badge>
  );
}
