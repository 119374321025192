import {
  Button,
  Col,
  Flex,
  Input,
  InputNumber,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import ImageInput from "./ImageInput";
import { PlusOutlined } from "@ant-design/icons";
import TagList from "./TagList";
import { Category, Tag } from "./domain/domain";
import { DEFAULT_IMG } from "./Utils";

export const TourEditor = ({ tour, update, getImgUrl, upload, dismiss }) => {
  return (
    <Modal
      title={"Editor"}
      open={!!tour}
      footer={null}
      closable
      width={1200}
      onCancel={dismiss}
    >
      <Row align={"top"} style={{ marginTop: 24 }}>
        <Col span={3}>
          <Row justify={"end"}>
            <Typography.Text>Images</Typography.Text>
            <Typography.Text
              style={{ marginInlineStart: 2, marginInlineEnd: 8 }}
            >
              :
            </Typography.Text>
          </Row>
        </Col>
        <Col span={19}>
          <Flex wrap="wrap" gap={16} align="center">
            {tour.images.map((image, index) => (
              <ImageInput
                id={"images_" + index}
                imageUrl={getImgUrl(
                  image,
                  window.devicePixelRatio * 160,
                  window.devicePixelRatio * 120
                )}
                upload={(file, path) =>
                  upload(file, path).then((key) => {
                    tour.images[index] = key;
                    update(tour);
                  })
                }
                onRemove={() => {
                  update({
                    ...tour,
                    images: tour.images.filter((img, i) => i !== index),
                  });
                }}
              />
            ))}

            <Button
              type={"dashed"}
              size={"small"}
              title={"Add"}
              onClick={() => {
                tour.images.push(DEFAULT_IMG);
                update(tour);
              }}
            >
              <PlusOutlined />
            </Button>
          </Flex>
        </Col>
      </Row>

      <Row align={"top"} style={{ marginTop: 24 }}>
        <Col span={3}>
          <Row justify={"end"}>
            <Typography.Text>Name</Typography.Text>
            <Typography.Text
              style={{ marginInlineStart: 2, marginInlineEnd: 8 }}
            >
              :
            </Typography.Text>
          </Row>
        </Col>
        <Col span={19}>
          <Input
            placeholder="Tour Name"
            value={tour.name}
            onChange={(event) => {
              tour.name = event.target.value;
              update(tour);
            }}
          />
        </Col>
      </Row>

      <Row align={"top"} style={{ marginTop: 24 }}>
        <Col span={3}>
          <Row justify={"end"}>
            <Typography.Text>Highlights</Typography.Text>
            <Typography.Text
              style={{ marginInlineStart: 2, marginInlineEnd: 8 }}
            >
              :
            </Typography.Text>
          </Row>
        </Col>
        <Col span={19}>
          <Input.TextArea
            value={tour.highlights}
            onChange={(e) => {
              tour.highlights = e.target.value;
              update(tour);
            }}
          />
        </Col>
      </Row>

      <Row align={"top"} style={{ marginTop: 24 }}>
        <Col span={3}>
          <Row justify={"end"}>
            <Typography.Text>Expectations</Typography.Text>
            <Typography.Text
              style={{ marginInlineStart: 2, marginInlineEnd: 8 }}
            >
              :
            </Typography.Text>
          </Row>
        </Col>
        <Col span={19}>
          <Input.TextArea
            value={tour.expectations}
            onChange={(e) => {
              tour.expectations = e.target.value;
              update(tour);
            }}
          />
        </Col>
      </Row>

      <Row align={"top"} style={{ marginTop: 24 }}>
        <Col span={3}>
          <Row justify={"end"}>
            <Typography.Text>Activities</Typography.Text>
            <Typography.Text
              style={{ marginInlineStart: 2, marginInlineEnd: 8 }}
            >
              :
            </Typography.Text>
          </Row>
        </Col>
        <Col span={19}>
          <Flex wrap="wrap" gap={16} align="center">
            {tour.activities.map((activity, index) => (
              <Flex vertical>
                <ImageInput
                  id={"activities_" + index}
                  imageUrl={getImgUrl(
                    activity,
                    160 * window.devicePixelRatio,
                    120 * window.devicePixelRatio
                  )}
                  upload={(file, path) =>
                    upload(file, path).then((key) => {
                      tour.activities[index] = key;
                      update(tour);
                    })
                  }
                  onRemove={() => {
                    update({
                      ...tour,
                      activities: tour.activities.filter(
                        (img, i) => i !== index
                      ),
                      captions: tour.captions.filter((cap, i) => i !== index),
                    });
                  }}
                />
                <Input.TextArea
                  style={{ width: 160, marginTop: 0 }}
                  value={tour.captions[index]}
                  onChange={(e) => {
                    tour.captions[index] = e.target.value;
                    update(tour);
                  }}
                />
              </Flex>
            ))}

            <Button
              title={"Add"}
              size={"small"}
              type={"dashed"}
              onClick={() => {
                tour.activities.push(DEFAULT_IMG);
                tour.captions.push("Activity Description");
                update({ ...tour });
              }}
            >
              <PlusOutlined />
            </Button>
          </Flex>
        </Col>
      </Row>

      <Row align={"top"} style={{ marginTop: 24 }}>
        <Col span={3}>
          <Row justify={"end"}>
            <Typography.Text>Inclusions</Typography.Text>
            <Typography.Text
              style={{ marginInlineStart: 2, marginInlineEnd: 8 }}
            >
              :
            </Typography.Text>
          </Row>
        </Col>
        <Col span={19}>
          <TagList
            tags={tour?.inclusions}
            color={"green"}
            setTags={(inclusions) => {
              update({ ...tour, inclusions: inclusions });
            }}
          />
        </Col>
      </Row>

      <Row align={"top"} style={{ marginTop: 24 }}>
        <Col span={3}>
          <Row justify={"end"}>
            <Typography.Text>Exclusions</Typography.Text>
            <Typography.Text
              style={{ marginInlineStart: 2, marginInlineEnd: 8 }}
            >
              :
            </Typography.Text>
          </Row>
        </Col>
        <Col span={19}>
          <TagList
            tags={tour?.exclusions}
            color={"red"}
            setTags={(exclusions) => {
              update({ ...tour, exclusions: exclusions });
            }}
          />
        </Col>
      </Row>

      <Row align={"top"} style={{ marginTop: 24 }}>
        <Col span={3}>
          <Row justify={"end"}>
            <Typography.Text>Meeting</Typography.Text>
            <Typography.Text
              style={{ marginInlineStart: 2, marginInlineEnd: 8 }}
            >
              :
            </Typography.Text>
          </Row>
        </Col>
        <Col span={19}>
          <Popover
            placement="right"
            content={
              <Space direction="vertical">
                <Input.TextArea
                  placeholder="Address"
                  value={tour.address?.content}
                  onChange={(event) => {
                    if (tour.address) {
                      tour.address.content = event.target.value;
                    } else {
                      tour.address = { content: event.target.value };
                    }
                    update({ ...tour });
                  }}
                />
                <InputNumber
                  style={{ width: 300 }}
                  step="0.000001"
                  placeholder="Latitude"
                  value={tour.address?.lat}
                  onChange={(value) => {
                    if (tour.address) {
                      tour.address.lat = value;
                    } else {
                      tour.address = { lat: value };
                    }
                    update({ ...tour });
                  }}
                />
                <InputNumber
                  style={{ width: 300 }}
                  step="0.000001"
                  placeholder="Longitude"
                  value={tour.address?.lng}
                  onChange={(value) => {
                    if (tour.address) {
                      tour.address.lng = value;
                    } else {
                      tour.address = { lng: value };
                    }
                    update({ ...tour });
                  }}
                />
                <Button
                  type="primary"
                  size="small"
                  danger
                  onClick={() => {
                    delete tour.address;
                    update({ ...tour });
                  }}
                >
                  Delete
                </Button>
              </Space>
            }
            title="Meeting"
            trigger="click"
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill={"#333"}
                fill-rule="evenodd"
                d="M11.291 21.706 12 21l-.709.706zM12 21l.708.706a1 1 0 0 1-1.417 0l-.006-.007-.017-.017-.062-.063a47.708 47.708 0 0 1-1.04-1.106 49.562 49.562 0 0 1-2.456-2.908c-.892-1.15-1.804-2.45-2.497-3.734C4.535 12.612 4 11.248 4 10c0-4.539 3.592-8 8-8 4.408 0 8 3.461 8 8 0 1.248-.535 2.612-1.213 3.87-.693 1.286-1.604 2.585-2.497 3.735a49.583 49.583 0 0 1-3.496 4.014l-.062.063-.017.017-.006.006L12 21zm0-8a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                clip-rule="evenodd"
              />
            </svg>
          </Popover>
        </Col>
      </Row>

      <Row align={"top"} style={{ marginTop: 24 }}>
        <Col span={3}>
          <Row justify={"end"}>
            <Typography.Text>Category</Typography.Text>
            <Typography.Text
              style={{ marginInlineStart: 2, marginInlineEnd: 8 }}
            >
              :
            </Typography.Text>
          </Row>
        </Col>
        <Col span={19}>
          <Select
            value={tour?.category}
            style={{ width: 200 }}
            placeholder={"Select a Category"}
            options={Object.values(Category).map((category) => {
              return {
                label: category.toUpperCase().replaceAll("-", " "),
                value: category,
              };
            })}
            onChange={(value) => update({ ...tour, category: value })}
          />
        </Col>
      </Row>

      <Row align={"top"} style={{ marginTop: 24 }}>
        <Col span={3}>
          <Row justify={"end"}>
            <Typography.Text>Tag</Typography.Text>
            <Typography.Text
              style={{ marginInlineStart: 2, marginInlineEnd: 8 }}
            >
              :
            </Typography.Text>
          </Row>
        </Col>
        <Col span={19}>
          <Select
            value={tour?.tag}
            style={{ width: 200 }}
            placeholder={"Select a Tag"}
            options={Object.values(Tag).map((tag) => {
              return {
                label: tag.toUpperCase().replaceAll("-", " "),
                value: tag,
              };
            })}
            onChange={(value) => update({ ...tour, tag: value })}
          />
        </Col>
      </Row>

      <Row align={"top"} style={{ marginTop: 24 }}>
        <Col span={3}>
          <Row justify={"end"}>
            <Typography.Text>Price</Typography.Text>
            <Typography.Text
              style={{ marginInlineStart: 2, marginInlineEnd: 8 }}
            >
              :
            </Typography.Text>
          </Row>
        </Col>
        <Col span={19}>
          <InputNumber
            style={{ width: 200 }}
            min={0}
            max={10000}
            addonAfter={"€"}
            placeholder="Insert Price"
            value={tour?.price}
            onChange={(value) => {
              update({ ...tour, price: value });
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
};
