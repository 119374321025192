export enum Interval {
  TWO = 2,
  SIX = 6,
  TWELVE = 12,
}

export const FONTS = [
  "Quicksand",
  "Poppins",
  "Montserrat",
  "Roboto Flex",
  "Raleway",
  "Kurale",
  "Tenor",
];

export enum Category {
  CITY_WALKS = "city-walks",
  GUIDED_TOUR = "guided-tours",
  EXPEDIENCES = "experiences",
}

export enum Tag {
  NEW = "new",
  PROMOTION = "promotion",
  LIKELY_TO_SELLOUT = "likely-to-sell-out",
  BEST_VALUE = "best-value",
  BESTSELLER = "bestseller",
}

export enum CalendarType {
  AVAILABILITIES = "Availabilities",
  RESERVATIONS = "Reservations",
  HISTORY = "History",
}

export enum ComponentType {
  TITLE = "Title",
  PARAGRAPH = "Paragraph",
  INFO = "InfoCards",
  PRODUCTS = "Products",
  FAQ = "Faq",
  SPACE = "Space",
}

export enum InputType {
  Text = "Text",
  TextArea = "TextArea",
  Image = "Image",
  Tour = "Tour",
  Audible = "Audible",
  Link = "Link",
  Product = "Product",
}

export enum BorderRadius {
  None = "0",
  Medium = "6px",
  Large = "12px",
  ExtraLarge = "18px",
}

export enum CardSize {
  Small = "210px",
  Medium = "240px",
  Large = "300px",
  ExtraLarge = "360px",
}

export enum FontSize {
  Small = "0.8rem",
  Medium = "1rem",
  Large = "1.3rem",
  ExtraLarge = "1.6rem",
}

export enum Backgrounds {
  Light = "#FFFFFF",
  Grey = "#DDDDDD",
  Dark = "#000000",
}

export const COLORS = [
  {
    name: "Indigo",
    value: "#3E50B2",
    text: "#FFFFFF",
  },
  {
    name: "Brown",
    value: "#715042",
    text: "#FFFFFF",
  },
  {
    name: "Orange",
    value: "#9D3110",
    text: "#FFFFFF",
  },
  {
    name: "Green",
    value: "#30631C",
    text: "#FFFFFF",
  },
  {
    name: "Cyan",
    value: "#00616B",
    text: "#FFFFFF",
  },
  {
    name: "Grey",
    value: "#595959",
    text: "#FFFFFF",
  },
];

export class Chapter {
  name: string;
  position: number;
  top: number;
  left: number;
  pole: string;
}

export class Slide {
  img: string;
  position: number;
}

export class Slot {
  sId: string;
  seats: number;
  price: number;
  dPrice?: number;
  dSeats?: number;
}

export class Session {
  tId: string;
  sId: string;
  expiresAt: number;
  paymentStatus: string;
  workStatus: string;
  seats: number;
  price: number;
  name?: string;
  email?: string;
}

export class Product {
  tenantId: string;
  sId: string;
}

export class Audible extends Product {
  lang?: string;
  city?: string;
  name?: string;
  desc?: string;
  audio?: string;
  vtt?: string;
  slides?: Slide[];
  chapters?: Chapter[];
}

export class Tour extends Product {
  name?: string;
  highlights?: string;
  expectations?: string;
  price?: number;
  category?: string;
  images?: string[];
  city?: string;
  lang?: string;
  desc?: string;
  long?: string;
  slots?: Slot[];
  inclusions?: string[];
  exclusions?: string[];
  activities?: string[];
  captions?: string[];
  links?: string[];
}

export class Activity {
  name: string;
  img?: string;
}

export class Component {
  type: ComponentType;
  title?: string;
  description?: string;
  image?: string;
  elements?: any[];
}

export class Page {
  name: string;
  sId: string;
  components: Component[];
}

export class MapMarker {
  content: string;
  lat: number;
  lng: number;
}

export class Config {
  tId: string;
  apiKey: string;
  api: string;
  website: string;
  pages: Array<Page>;
  primaryColor: string;
  textColor: string;
  primaryDarkColor: string;
  backgroundColor: string;
  primaryFont: string;
  secondaryFont: string;
  fontSize: string;
  cardSize: string;
  borderRadius: string;
  imgServer: string;
  imgQuality: number;
  fullLogo: string;
  squareLogo: string;
  ghostLogo: string;
  mapMarker: string;
  companyName: string;
  googleSiteVerification: string;
  googleTagManager: string;
  tours: Array<Tour>;
  audibles: Array<Audible>;
  blogs: Array<Page>;
  facebook?: string;
  youtube?: string;
  instagram?: string;
  tiktok?: string;
  x?: string;
  linkedIn?: string;
  phone?: string;
  email?: string;
  address?: MapMarker;
}
