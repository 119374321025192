import React from "react";

// Define the types for the props
interface CardProps {
  icon: any;
  title: string;
  content: string;
}

interface AccentSlidesProps {
  mainTitle: string;
  subTitle: string;
  cards: CardProps[];
}

// Define the Card component
const Card: React.FC<CardProps> = ({ icon, title, content }) => {
  return (
    <div style={styles.card}>
      <p style={{ fontSize: 48, margin: 0 }}>{icon}</p>
      <p style={{ fontSize: 24, margin: 0, fontWeight: "bolder" }}>{title}</p>
      <p>{content}</p>
    </div>
  );
};

// Define the AccentSlides component
export const AccentSlides: React.FC<AccentSlidesProps> = ({
  mainTitle,
  subTitle,
  cards,
}) => {
  return (
    <div style={styles.container}>
      <h1 style={{ fontSize: 48, margin: 0 }}>{mainTitle}</h1>
      <p style={{ maxWidth: 300, margin: "auto" }}>{subTitle}</p>
      <div style={styles.cardsContainer} id="slides">
        {cards.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
    </div>
  );
};

// Define some basic styles
const styles = {
  container: {
    color: "white",
    background: "linear-gradient(to right, #064c5a, #047d95)",
    textAlign: "center" as "center",
    padding: "40px",
    paddingTop: "150px",
    paddingBottom: "100px",
    marginTop: "150px",
  },
  cardsContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "20px",
  },
  card: {
    margin: 20,
    maxWidth: 300,
  },
  icon: {
    width: "50px",
    height: "50px",
    marginBottom: "10px",
  },
};
