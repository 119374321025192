import { Authenticator } from "@aws-amplify/ui-react";
import Logo from "./assets/logo.svg";
import LandingPage from "./LandingPage";
import App from "./App";

import { ArrowLeftOutlined } from "@ant-design/icons";

import { Space, Typography } from "antd";
import { Auth } from "aws-amplify";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Subscribe } from "./Subscribe";
import { environment } from "./environments/environment";
export const Cognito = () => {
  const navigate = useNavigate();
  Auth.currentAuthenticatedUser()
    .then((user) => {
      console.log("AUTH.THEN", user);
    })
    .catch((err) => {
      console.log("AUTH.ERR", err);
    });
  const components = {
    Header() {
      return (
        <div id="heros">
          <img src={Logo} width={100} height={100} />
          <h1 className="title" style={{ textAlign: "center" }}>
            {environment.name}
          </h1>
          {window.location.search.includes("sub") ? (
            <p style={{ textAlign: "center" }}>
              We've sent you a temporary password via email.
              <br />
              After the first sign in you will be asked to choose a new
              password.
            </p>
          ) : null}
          <Space
            onClick={() => navigate("/")}
            style={{
              position: "fixed",
              cursor: "pointer",
              top: 20,
              left: 20,
            }}
          >
            <ArrowLeftOutlined />
            <Typography.Text>Back</Typography.Text>
          </Space>
        </div>
      );
    },
  };

  return (
    <Routes>
      <Route index element={<LandingPage />} />
      <Route
        path="subscribe"
        element={<Subscribe apiKey={environment.apiKey} />}
      />
      <Route
        path="admin/*"
        element={
          <Authenticator components={components} hideSignUp={true}>
            {({ signOut, user }) => <App user={user} signOut={signOut} />}
          </Authenticator>
        }
      />
    </Routes>
  );
};
