import * as React from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Input,
  InputNumber,
  Popover,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import ImageInput from "./ImageInput";
import { COMPLETE_LOGO, GHOST_LOGO, SQUARE_LOGO } from "./Utils";
import {
  EditFilled,
  EyeFilled,
  InfoCircleFilled,
  InfoCircleOutlined,
  LinkOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import Paragraph from "antd/es/skeleton/Paragraph";
import { BorderRadius, COLORS, FONTS } from "./domain/domain";
// If using TypeScript, add the following snippet to your file as well.
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

function Settings({ openBilling, config, setConfig, getImgUrl, upload, user }) {
  return (
    <Flex gap={24} wrap="wrap" style={{ maxWidth: "50vw", margin: "auto" }}>
      <Space direction="vertical">
        <Typography.Text strong>Full:</Typography.Text>

        <ImageInput
          id={"logo"}
          imageUrl={user.attributes["website"] + "/images/" + config.fullLogo}
          upload={(file) =>
            upload(file, "images/").then((key) => {
              config.fullLogo = key;
              setConfig({ ...config });
            })
          }
          contentType="image/svg+xml"
          onRemove={null}
          width={256}
          height={128}
        />
      </Space>

      <Space direction="vertical">
        <Typography.Text strong>Square:</Typography.Text>

        <ImageInput
          id={"square"}
          imageUrl={user.attributes["website"] + "/images/" + config.squareLogo}
          upload={(file) =>
            upload(file, "images/").then((key) => {
              config.squareLogo = key;
              setConfig({ ...config });
            })
          }
          onRemove={null}
          contentType="image/svg+xml"
          width={128}
          height={128}
        />
      </Space>

      <Space direction="vertical">
        <Typography.Text strong>Ghost:</Typography.Text>

        <ImageInput
          id={"ghost"}
          imageUrl={user.attributes["website"] + "/images/" + config.ghostLogo}
          upload={(file) =>
            upload(file, "images/").then((key) => {
              config.ghostLogo = key;
              setConfig({ ...config });
            })
          }
          onRemove={null}
          contentType="image/svg+xml"
          backgroundColor={config.primaryColor}
          width={128}
          height={128}
        />
      </Space>

      <Space direction="vertical">
        <Typography.Text strong>MapMarker:</Typography.Text>

        <ImageInput
          id={"map_marker"}
          imageUrl={user.attributes["website"] + "/images/" + config.mapMarker}
          upload={(file) =>
            upload(file, "images/").then((key) => {
              config.mapMarker = key;
              setConfig({ ...config });
            })
          }
          onRemove={null}
          contentType="image/svg+xml"
          width={90}
          height={120}
        />
      </Space>
      <Divider />
      <Space direction="vertical">
        <Typography.Text strong>Contacts:</Typography.Text>
        <Space>
          <Popover
            placement="right"
            content={
              <Input
                value={config.phone}
                onChange={(event) => {
                  config.phone = event.target.value;
                  setConfig({ ...config });
                }}
              />
            }
            title="Phone number"
            trigger="click"
          >
            <svg
              fill={config.phone ? config.primaryColor : "#ccc"}
              width="48"
              height="48"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 5.5C3 14.0604 9.93959 21 18.5 21C18.8862 21 19.2691 20.9859 19.6483 20.9581C20.0834 20.9262 20.3009 20.9103 20.499 20.7963C20.663 20.7019 20.8185 20.5345 20.9007 20.364C21 20.1582 21 19.9181 21 19.438V16.6207C21 16.2169 21 16.015 20.9335 15.842C20.8749 15.6891 20.7795 15.553 20.6559 15.4456C20.516 15.324 20.3262 15.255 19.9468 15.117L16.74 13.9509C16.2985 13.7904 16.0777 13.7101 15.8683 13.7237C15.6836 13.7357 15.5059 13.7988 15.3549 13.9058C15.1837 14.0271 15.0629 14.2285 14.8212 14.6314L14 16C11.3501 14.7999 9.2019 12.6489 8 10L9.36863 9.17882C9.77145 8.93713 9.97286 8.81628 10.0942 8.64506C10.2012 8.49408 10.2643 8.31637 10.2763 8.1317C10.2899 7.92227 10.2096 7.70153 10.0491 7.26005L8.88299 4.05321C8.745 3.67376 8.67601 3.48403 8.55442 3.3441C8.44701 3.22049 8.31089 3.12515 8.15802 3.06645C7.98496 3 7.78308 3 7.37932 3H4.56201C4.08188 3 3.84181 3 3.63598 3.09925C3.4655 3.18146 3.29814 3.33701 3.2037 3.50103C3.08968 3.69907 3.07375 3.91662 3.04189 4.35173C3.01413 4.73086 3 5.11378 3 5.5Z"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Popover>

          <Popover
            placement="right"
            content={
              <Input
                value={config.email}
                onChange={(event) => {
                  config.email = event.target.value;
                  setConfig({ ...config });
                }}
              />
            }
            title="Email"
            trigger="click"
          >
            <svg
              fill={config.phone ? config.primaryColor : "#ccc"}
              width="48"
              height="48"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" opacity="0" />
              <path d="M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zm-.67 2L12 10.75 5.67 6zM19 18H5a1 1 0 0 1-1-1V7.25l7.4 5.55a1 1 0 0 0 .6.2 1 1 0 0 0 .6-.2L20 7.25V17a1 1 0 0 1-1 1z" />
            </svg>
          </Popover>

          <Popover
            placement="right"
            content={
              <Space direction="vertical">
                <Input.TextArea
                  placeholder="Address"
                  value={config.address?.content}
                  onChange={(event) => {
                    if (config.address) {
                      config.address.content = event.target.value;
                    } else {
                      config.address = { content: event.target.value };
                    }
                    setConfig({ ...config });
                  }}
                />
                <InputNumber
                  style={{ width: 300 }}
                  step="0.000001"
                  placeholder="Latitude"
                  value={config.address?.lat}
                  onChange={(value) => {
                    if (config.address) {
                      config.address.lat = value;
                    } else {
                      config.address = { lat: value };
                    }
                    setConfig({ ...config });
                  }}
                />
                <InputNumber
                  style={{ width: 300 }}
                  step="0.000001"
                  placeholder="Longitude"
                  value={config.address?.lng}
                  onChange={(value) => {
                    if (config.address) {
                      config.address.lng = value;
                    } else {
                      config.address = { lng: value };
                    }
                    setConfig({ ...config });
                  }}
                />
                <Button
                  type="primary"
                  size="small"
                  danger
                  onClick={() => {
                    delete config.address;
                    setConfig({ ...config });
                  }}
                >
                  Delete
                </Button>
              </Space>
            }
            title="Address"
            trigger="click"
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill={config.address ? config.primaryColor : "#ccc"}
                fill-rule="evenodd"
                d="M11.291 21.706 12 21l-.709.706zM12 21l.708.706a1 1 0 0 1-1.417 0l-.006-.007-.017-.017-.062-.063a47.708 47.708 0 0 1-1.04-1.106 49.562 49.562 0 0 1-2.456-2.908c-.892-1.15-1.804-2.45-2.497-3.734C4.535 12.612 4 11.248 4 10c0-4.539 3.592-8 8-8 4.408 0 8 3.461 8 8 0 1.248-.535 2.612-1.213 3.87-.693 1.286-1.604 2.585-2.497 3.735a49.583 49.583 0 0 1-3.496 4.014l-.062.063-.017.017-.006.006L12 21zm0-8a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                clip-rule="evenodd"
              />
            </svg>
          </Popover>
        </Space>
      </Space>

      <Divider />
      <Space direction="vertical">
        <Typography.Text strong>Social links:</Typography.Text>
        <Space>
          <Popover
            placement="right"
            content={
              <Input
                value={config.facebook}
                onChange={(event) => {
                  config.facebook = event.target.value;
                  setConfig({ ...config });
                }}
              />
            }
            title="Facebook"
            trigger="click"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="48"
              height="48"
              viewBox="0 0 30 30"
              fill={config.facebook ? config.primaryColor : "#ccc"}
            >
              <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h10v-9h-3v-3h3v-1.611C16,9.339,17.486,8,20.021,8 c1.214,0,1.856,0.09,2.16,0.131V11h-1.729C19.376,11,19,11.568,19,12.718V14h3.154l-0.428,3H19v9h5c1.105,0,2-0.895,2-2V6 C26,4.895,25.104,4,24,4z"></path>
            </svg>
          </Popover>

          <Popover
            placement="right"
            content={
              <Input
                value={config.tiktok}
                onChange={(event) => {
                  config.tiktok = event.target.value;
                  setConfig({ ...config });
                }}
              />
            }
            title="Tiktok"
            trigger="click"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="48"
              height="48"
              viewBox="0 0 30 30"
              fill={config.tiktok ? config.primaryColor : "#ccc"}
            >
              <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.104,4,24,4z M22.689,13.474 c-0.13,0.012-0.261,0.02-0.393,0.02c-1.495,0-2.809-0.768-3.574-1.931c0,3.049,0,6.519,0,6.577c0,2.685-2.177,4.861-4.861,4.861 C11.177,23,9,20.823,9,18.139c0-2.685,2.177-4.861,4.861-4.861c0.102,0,0.201,0.009,0.3,0.015v2.396c-0.1-0.012-0.197-0.03-0.3-0.03 c-1.37,0-2.481,1.111-2.481,2.481s1.11,2.481,2.481,2.481c1.371,0,2.581-1.08,2.581-2.45c0-0.055,0.024-11.17,0.024-11.17h2.289 c0.215,2.047,1.868,3.663,3.934,3.811V13.474z"></path>
            </svg>
          </Popover>

          <Popover
            placement="right"
            content={
              <Input
                value={config.youtube}
                onChange={(event) => {
                  config.youtube = event.target.value;
                  setConfig({ ...config });
                }}
              />
            }
            title="Youtube"
            trigger="click"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="48"
              height="48"
              viewBox="0 0 30 30"
              fill={config.youtube ? config.primaryColor : "#ccc"}
            >
              <path d="M 15 4 C 10.814 4 5.3808594 5.0488281 5.3808594 5.0488281 L 5.3671875 5.0644531 C 3.4606632 5.3693645 2 7.0076245 2 9 L 2 15 L 2 15.001953 L 2 21 L 2 21.001953 A 4 4 0 0 0 5.3769531 24.945312 L 5.3808594 24.951172 C 5.3808594 24.951172 10.814 26.001953 15 26.001953 C 19.186 26.001953 24.619141 24.951172 24.619141 24.951172 L 24.621094 24.949219 A 4 4 0 0 0 28 21.001953 L 28 21 L 28 15.001953 L 28 15 L 28 9 A 4 4 0 0 0 24.623047 5.0546875 L 24.619141 5.0488281 C 24.619141 5.0488281 19.186 4 15 4 z M 12 10.398438 L 20 15 L 12 19.601562 L 12 10.398438 z"></path>
            </svg>
          </Popover>

          <Popover
            placement="right"
            content={
              <Input
                value={config.instagram}
                onChange={(event) => {
                  config.instagram = event.target.value;
                  setConfig({ ...config });
                }}
              />
            }
            title="Instagram"
            trigger="click"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="48"
              height="48"
              viewBox="0 0 30 30"
              fill={config.instagram ? config.primaryColor : "#ccc"}
            >
              <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"></path>
            </svg>
          </Popover>

          <Popover
            placement="right"
            content={
              <Input
                value={config.x}
                onChange={(event) => {
                  config.x = event.target.value;
                  setConfig({ ...config });
                }}
              />
            }
            title="X"
            trigger="click"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="48"
              height="48"
              viewBox="0 0 30 30"
              fill={config.x ? config.primaryColor : "#ccc"}
            >
              <path d="M 6 4 C 4.895 4 4 4.895 4 6 L 4 24 C 4 25.105 4.895 26 6 26 L 24 26 C 25.105 26 26 25.105 26 24 L 26 6 C 26 4.895 25.105 4 24 4 L 6 4 z M 8.6484375 9 L 13.259766 9 L 15.951172 12.847656 L 19.28125 9 L 20.732422 9 L 16.603516 13.78125 L 21.654297 21 L 17.042969 21 L 14.056641 16.730469 L 10.369141 21 L 8.8945312 21 L 13.400391 15.794922 L 8.6484375 9 z M 10.878906 10.183594 L 17.632812 19.810547 L 19.421875 19.810547 L 12.666016 10.183594 L 10.878906 10.183594 z"></path>
            </svg>
          </Popover>

          <Popover
            placement="right"
            content={
              <Input
                value={config.linkedIn}
                onChange={(event) => {
                  config.linkedIn = event.target.value;
                  setConfig({ ...config });
                }}
              />
            }
            title="LinkedIn"
            trigger="click"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="48"
              height="48"
              viewBox="0 0 50 50"
              fill={config.linkedIn ? config.primaryColor : "#ccc"}
            >
              <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
            </svg>
          </Popover>
        </Space>
      </Space>
      <Divider />

      <Space direction="vertical">
        <Typography.Text strong>PrimaryColor:</Typography.Text>

        <Space>
          {COLORS.map((color) => (
            <Tag
              onClick={() => {
                config.primaryColor = color.value;
                config.textColor = color.text;
                config.primaryDarkColor = color.value;
                setConfig({ ...config });
              }}
              color={color.value === config.primaryColor ? color.value : "#ccc"}
              style={{
                cursor: "pointer",
              }}
            >
              {color.name}
            </Tag>
          ))}
        </Space>
      </Space>

      <Divider />

      <Space direction="vertical">
        <Typography.Text strong>Titles Font:</Typography.Text>
        <Space>
          {FONTS.map((font) => (
            <Tag
              onClick={() => {
                config.primaryFont = font;
                setConfig({ ...config });
              }}
              color={font === config.primaryFont ? config.primaryColor : "#ccc"}
              style={{
                cursor: "pointer",
              }}
            >
              {font}
            </Tag>
          ))}
        </Space>
      </Space>

      <Divider />

      <Space direction="vertical">
        <Typography.Text strong>Text Font:</Typography.Text>
        <Space>
          {FONTS.map((font) => (
            <Tag
              onClick={() => {
                config.secondaryFont = font;
                setConfig({ ...config });
              }}
              color={
                font === config.secondaryFont ? config.primaryColor : "#ccc"
              }
              style={{
                cursor: "pointer",
              }}
            >
              {font}
            </Tag>
          ))}
        </Space>
      </Space>

      <Divider />

      <Space direction="vertical">
        <Typography.Text strong>Border Radius:</Typography.Text>
        <Space>
          {Object.keys(BorderRadius).map((key) => (
            <Tag
              onClick={() => {
                config.borderRadius = BorderRadius[key];
                setConfig({ ...config });
              }}
              style={{ cursor: "pointer" }}
              color={
                BorderRadius[key] === config.borderRadius
                  ? config.primaryColor
                  : "#ccc"
              }
            >
              {key}
            </Tag>
          ))}
        </Space>
      </Space>

      <Divider />

      <Space direction="vertical">
        <Typography.Text strong>Name:</Typography.Text>

        <Popover
          placement="right"
          content={
            <Input
              value={config.companyName}
              onChange={(event) => {
                config.companyName = event.target.value;
                setConfig({ ...config });
              }}
            />
          }
          title="Name"
          trigger="click"
        >
          <Typography.Text>{config.companyName}</Typography.Text>
        </Popover>
      </Space>

      <Space direction="vertical">
        <Typography.Text strong>Highlights:</Typography.Text>

        <Popover
          placement="right"
          content={
            <Input.TextArea
              value={config.highlights}
              onChange={(event) => {
                config.highlights = event.target.value;
                setConfig({ ...config });
              }}
            />
          }
          title="Highlights"
          trigger="click"
        >
          <Typography.Paragraph>
            {config.highlights || <PlusCircleOutlined />}
          </Typography.Paragraph>
        </Popover>
      </Space>

      <Space direction="vertical">
        <Typography.Text strong>Description:</Typography.Text>

        <Popover
          placement="right"
          content={
            <Input.TextArea
              value={config.description}
              onChange={(event) => {
                config.description = event.target.value;
                setConfig({ ...config });
              }}
            />
          }
          title="Description"
          trigger="click"
        >
          <Typography.Paragraph>
            {config.description || <PlusCircleOutlined />}
          </Typography.Paragraph>
        </Popover>
      </Space>

      <Divider />

      <Space direction="vertical">
        <Typography.Text strong>Google Site Verification Tag:</Typography.Text>

        <Popover
          placement="right"
          content={
            <Input
              style={{ width: 500 }}
              value={config.googleSiteVerification}
              onChange={(event) => {
                config.googleSiteVerification = event.target.value;
                setConfig({ ...config });
              }}
            />
          }
          title="TAG"
          trigger="click"
        >
          <Typography.Paragraph>
            {config.googleSiteVerification || <PlusCircleOutlined />}
          </Typography.Paragraph>
        </Popover>
      </Space>

      <Space direction="vertical">
        <Typography.Text strong>Google GTM:</Typography.Text>

        <Popover
          placement="right"
          content={
            <Input
              value={config.googleTagManager}
              onChange={(event) => {
                config.googleTagManager = event.target.value;
                setConfig({ ...config });
              }}
            />
          }
          title="GTM"
          trigger="click"
        >
          <Typography.Text>
            {config.googleTagManager || <PlusCircleOutlined />}
          </Typography.Text>
        </Popover>
      </Space>

      <Divider />

      <Space direction="vertical">
        <Typography.Text strong>Subscription Status:</Typography.Text>
        <Row>
          <Tag>{user.attributes["custom:plan"]}</Tag>
          <Typography.Text></Typography.Text>
          <Typography.Link onClick={openBilling}>
            {user.attributes["custom:status"]}
          </Typography.Link>
        </Row>
      </Space>

      <Divider />

      <Space direction="vertical">
        <Typography.Text strong>Useful links:</Typography.Text>
        <Typography.Link>Terms and Conditions</Typography.Link>
      </Space>

      <Divider />
    </Flex>
  );
}

export default Settings;
